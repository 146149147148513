import Login from "views/pages/auth/Login"
import CookieError from "views/pages/auth/CookieError"

const routes = [
  {
    path: "/login",
    name: "Kirjaudu",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/cookies",
    name: "Virhe",
    component: CookieError,
    layout: "/auth",
  },
  /*
  {
    
    collapse: true,
    name: "Auth",
    icon: "ni ni-ungroup text-orange",
    state: "authCollapse",
    views: [
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/cookies",
        name: "CookieError",
        miniName: "C",
        component: CookieError,
        layout: "/auth",
      },
    ]
  }
  */
];

export default routes;