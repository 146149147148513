import Frontpage from "views/pages/dashboard/frontpage/Frontpage";
import CustomerUsers from "views/pages/dashboard/customerusers/CustomerUsers";
import CustomerUserEdit from "views/pages/dashboard/customeruseredit/CustomerUserEdit";
import CompanyUsers from "views/pages/dashboard/companyusers/CompanyUsers";
import CompanyUserAddView from "views/pages/dashboard/companyuseradd/CompanyUserAddView";
import CompanyUserEditView from "views/pages/dashboard/companyuseredit/CompanyUserEditView";
import Log from "views/pages/dashboard/log/Log";
import MyUser from "views/pages/dashboard/myuser/MyUser";
import Settings from "views/pages/dashboard/settings/Settings";
import Help from "views/pages/dashboard/help/Help";
import OrganizationsList from "views/pages/dashboard/organizationsList/OrganizationsList";
import OrganizationUsersList from "views/pages/dashboard/organizationUsersList/OrganizationUsersList";
import OrganizationUnitsList from "views/pages/dashboard/organizationUnitsList/OrganizationUnitsList";
import OrganizationEdit from "views/pages/dashboard/organizationEdit/OrganizationEdit";

const visibleRoutes = [
  {
    path: "/frontpage",
    name: "Etusivu",
    icon: "ni ni-shop text-primary",
    component: Frontpage,
    layout: "/dashboard",
  },
  {
    collapse: true,
    name: "Organisaatiot",
    icon: "ni ni-single-copy-04 text-primary",
    state: "organizationsCollapse",
    layout: "/dashboard",
    permissions: [3],
    views: [
      {
        path: "/organizations/list",
        name: "Organisaatiot",
        miniName: "O",
        component: OrganizationsList,
        layout: "/dashboard",
      },
      {
        path: "/organizations/users",
        name: "Organisaatiokäyttäjät",
        miniName: "OU",
        component: OrganizationUsersList,
        layout: "/dashboard",
      },
      {
        path: "/organizations/units",
        name: "Toimipisteet",
        miniName: "O",
        component: OrganizationUnitsList,
        layout: "/dashboard",
      },
    ],
  },
  {
    collapse: true,
    name: "Organisaatio",
    icon: "ni ni-single-copy-04 text-primary",
    state: "organizationsCollapse",
    layout: "/dashboard",
    permissions: [2],
    views: [
      {
        path: "/organizations/list",
        name: "Tiedot",
        miniName: "O",
        component: OrganizationsList,
        layout: "/dashboard",
      },
      {
        path: "/organizations/units",
        name: "Toimipisteet",
        miniName: "O",
        component: OrganizationUnitsList,
        layout: "/dashboard",
      },
      {
        path: "/organizations/users",
        name: "Käyttäjät",
        miniName: "OU",
        component: OrganizationUsersList,
        layout: "/dashboard",
      },
      
    ],
  },
  {
    path: "/customers",
    name: "Piirit",
    icon: "ni ni-single-02 text-primary",
    component: CustomerUsers,
    layout: "/dashboard",
  },
  /*
  {
    path: "/customers",
    name: "Asiakaskäyttäjät (Vanha)",
    icon: "ni ni-single-copy-04 text-primary",
    miniName: "A",
    component: CustomerUsers,
    layout: "/dashboard",
  },
  */
  /*
  {
    collapse: true,
    name: "Asiakaskäyttäjät",
    icon: "ni ni-single-copy-04 text-primary",
    state: "customersCollapse",
    layout: "/dashboard",
    views: [
      {
        path: "/customers",
        name: "Kaikki",
        miniName: "A",
        component: CustomerUsers,
        layout: "/dashboard",
      },
    ],
  },
  */
  {
    path: "/customers/edit/:key/",
    name: "Edit",
    icon: "ni ni-single-02 text-primary",
    component: CustomerUserEdit,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/users",
    name: "Kaikki käyttäjät",
    icon: "ni ni-single-02 text-primary",
    component: CompanyUsers,
    layout: "/dashboard",
    permissions: [3],
  },
  {
    path: "/users/add/",
    name: "Add",
    icon: "ni ni-single-02 text-primary",
    component: CompanyUserAddView,
    layout: "/dashboard",
    invisible: true,
    permissions: [3],
  },
  {
    path: "/users/edit/:id/",
    name: "Edit",
    icon: "ni ni-single-02 text-primary",
    component: CompanyUserEditView,
    layout: "/dashboard",
    invisible: true,
    // permissions: [3],
  },
  {
    path: "/log",
    name: "Loki",
    icon: "ni ni-archive-2 text-primary",
    component: Log,
    layout: "/dashboard",
    permissions: [3],
  },
  {
    hr: true,
  },
  {
    path: "/myuser",
    name: "Omat tiedot",
    icon: "ni ni-circle-08 text-primary",
    component: MyUser,
    layout: "/dashboard",
  },
  {
    path: "/settings",
    name: "Asetukset",
    icon: "ni ni-settings-gear-65 text-primary",
    component: Settings,
    layout: "/dashboard",
    permissions: [3],
  },
  {
    path: "/help",
    name: "Ohjeet",
    icon: "ni ni-support-16 text-primary",
    component: Help,
    layout: "/dashboard",
  },


];

const hiddenRoutes = [
  {
    path: "/organizations/edit/:key/",
    name: "Edit",
    icon: "ni ni-single-02 text-primary",
    component: OrganizationEdit,
    layout: "/dashboard",
    invisible: true,
    permissions: [3],
  },
]

const routes = [
  ...visibleRoutes,
  ...hiddenRoutes,
]

export default routes;
