import http, { authHeader } from './index'

const url = '/priima/users/'

export const httpGetCustomerUsers = (params) => {
  console.log("httpGetCustomerUsers");
  return http({
    url: url,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    params,
  })
}

export const httpGetCustomerUser = (userKey, params) => {
  console.log("httpGetCustomerUser");
  return http({
    url: `${url}${userKey}/`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    params,
  })
}

export const httpUpdateCustomerUser = (userKey, data) => {
  console.log("httpUpdateCustomerUser");
  return http({
    url: `${url}${userKey}/`,
    method: 'PATCH',
    headers: {
      ...authHeader(),
    },
    data,
  })
}
