import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";

// Storybook
import { Button, Card, Typography, IconButton, Modal, Link } from "stories/components";
import { Container, Row, Col } from "stories/layout";
import { Checkbox, Input } from "stories/forms";
import { TableAuto } from "stories/tables";

// Services
import { useStoreContext } from "contexts/StoreContext"
import { useAuthContext }from "contexts/AuthContext"
import { httpGetOrganizationUnits } from "services/organizations"

import OrganizationSelect from "components/OrganizationSelect/OrganizationSelect"
import OrganizationUnitEdit from "views/pages/dashboard/organizationUnitEdit/OrganizationUnitEdit";

const OrganizationUnitsList = ({ history }) => {
  const { enums } = useStoreContext();
  const { permissionLevel } = useAuthContext()
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    organization_fk: undefined,
  })
  
  const setFieldValue = (name, value) => {
    setFilters(s => ({
      ...s,
      [name]: value,
    }))
  }
  
  // Table
  
  const headers = [
    { label: "Toimipiste"},
    { label: "Organisaatio"},
    { label: ""},
  ];
  
  const [rows, setRows] = useState([]);
  
  const getTableRows = useCallback(() => {
    return rows && rows.map(row => {
      return [
        (
          <Typography>{row?.name}</Typography>
        ),
        (
          <Typography>{row?.organization_fk?.name}</Typography>
        ),
        (
          <>
            <IconButton size="xl" onClick={() => handleEdit(row?.id)} iconName="settings-gear-65" />
          </>
        )
      ]
    })
  }, [rows])

  const getOrganizationUnits = () => {
    setLoading(true);
    httpGetOrganizationUnits(filters).then(res => {
      setRows(res);
    }).finally(response => {
      setLoading(false);
    })
  }
  
  useEffect(() => {
    getOrganizationUnits()
  }, [])
  
  const handleFilterChange = ({ target }) => {
    const { value, name } = target;
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  
  // Modal
  
  const [selectedId, setSelectedId] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  
  const closeModal = () => {
    setSelectedId(null)
  }
  
  const handleCreate = () => {
    setSelectedId(0)
  }
  const handleEdit = (id) => {
    setSelectedId(id)
  }
  const resolveModal = () => {
    setSelectedId(null)
    getOrganizationUnits()
  }
  const rejectModal = () => {
    setSelectedId(null)
  }
  
  useEffect(() => {
    if (selectedId != null) {
      setModalOpen(true)
    }
    else {
      setModalOpen(false)
    }
  }, [selectedId])
  
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">

              <Row className="mb-3">
                <Col xs="6">
                  <Typography bold variant="h2" className="mb-0">Toimipisteet</Typography>
                  <Typography className="mb-0"></Typography>
                </Col>
                <Col xs="6" className="text-right">
                  <Button onClick={handleCreate}>Lisää toimipiste</Button>
                </Col>
              </Row>
              
              { permissionLevel === 3 ? (
              <form onSubmit={getOrganizationUnits}>
                <Row>
                  <Col>
                    <OrganizationSelect
                      name="organization_fk"
                      value={filters?.organization_fk}
                      setFieldValue={setFieldValue} 
                      label={null}
                      emptyOption={true}
                    />
                  </Col>
                  <Col><Button onClick={getOrganizationUnits}>Hae</Button></Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
              </form>
              ) : null}
                
              <TableAuto
                checkboxes={false}
                headers={headers}
                rows={getTableRows()}
                loading={loading}
              />

          </div>
        </Row>
      </Container>
      
      <Modal
      /* size="modal-xl" */
        title={"Toimipiste"}
        isOpen={modalOpen}
        toggleModal={closeModal}
        closeModal={closeModal}
      >
        <OrganizationUnitEdit
          organizationUnitId={selectedId}
          handleResolve={resolveModal}
          handleReject={rejectModal}
          inModal={true}
        />
        
      </Modal>
      
    </>
  );
}

export default OrganizationUnitsList;
