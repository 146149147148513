import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal as BootstrapModal,
} from "reactstrap";
import './Modal.css';

export const Modal = ({ title, isOpen, fullscreen=false, toggleModal, buttons, className, size, children, ...rest }) => {
  
  return (
    <BootstrapModal
      className={`modal-dialog-centered ${className ? className : ""} ${size ? size : ""} ${fullscreen ? 'modal_fullscreen' : ''}`}
      isOpen={isOpen}
      toggle={toggleModal}
      {...rest}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
        {title}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggleModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className={`modal-body pt-0 ${fullscreen ? 'modal_fullscreen_body' : ''}`}>
      {children}
      </div>
      
      { buttons && (
        <div className="modal-footer">
          {buttons}
        </div>
      )}
    </BootstrapModal>
  )
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  fullscreen: PropTypes.bool,
  toggleModal: PropTypes.func,
  buttons: PropTypes.array,
  title: PropTypes.string,
  size: PropTypes.oneOf(['modal-sm','modal-lg','modal-xl','modal-fullscreen']),
};

Modal.defaultProps = {
  isOpen: false,
  fullscreen: false,
};
