import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

// reactstrap components
import {
  CardHeader,
  CardBody,
} from "reactstrap";

// core components
import { Typography } from "stories/components/Typography"
import { Button } from "stories/components/Button"
import { Card } from "stories/components/Card"
import { Container } from "stories/layout/Container"
import { Row } from "stories/layout/Row"
import { Col } from "stories/layout/Col"

const Login = () => {

  const { loginWithRedirect  } = useAuth0();
  
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  return (
    <>
      <Container className="mt-8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7" className="">
            <Card variant="primary">
              <CardHeader className="bg-transparent pt-5 pb-1">
                
                <div className="text-center text-muted mb-4">
                  <Typography variant="h2" className="mb-2">Kirjaudu sisään</Typography>
                  <Typography variant="p">Palvelu on tarkoitettu vain organisaatiokäyttäjille</Typography>
                </div>
              
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
              
                <div className="text-center text-muted mb-4">
                  <Button className="my-4" color="primary" type="button" onClick={() => loginWithRedirect()}>
                    Kirjaudu sisään
                  </Button>
                </div>
                
              </CardBody>
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
