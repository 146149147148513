import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from "./index"
import './FormContainer.css';

export const FormContainer = ({ children, ...rest }) => {
  
  return (
    <Container fluid>
      <Row>
        <Col sm={12} md={8} lg={6} xl={4}  {...rest}>
          {children}
        </Col>
      </Row>
    </Container>
  )
};

FormContainer.propTypes = {
  
};


FormContainer.defaultProps = {
  
};

