import React, { useEffect, useState, useCallback} from "react";
import moment from "moment";

import { httpGetUsers } from "services/companyUsers"
import { useAuthContext } from "contexts/AuthContext"
import { Button, Card, Typography, IconButton, Link } from "stories/components";
import { Container, Row, Col } from "stories/layout";
import { Checkbox, Input } from "stories/forms";
import { TableAuto } from "stories/tables";

const headers = [
  { label: ""},
  { label: "Käyttäjä"},
  { label: "Sähköposti"},
  { label: "Puhelinnumero"},
  { label: "Tyyppi"},
  { label: "Tila"},
  { label: ""},
];

function CompanyUsers({history}) {
  
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([]);
  const { myUser } = useAuthContext();
  const [filters, setFilters] = useState({})
  
  
  const handleAdd = (id) => {
    history.push(`/dashboard/users/add`);
  }
  
  const handleEdit = (id) => {
    history.push(`/dashboard/users/edit/${id}/`);
  }
  
  const getStatus = (activatedDate) => {
    
    if (!activatedDate || moment(activatedDate).isBefore(moment())) {
      return (
        <Typography bold className="text-danger">Deaktiivinen</Typography>
      )
    }
    else {
      return (
        <Typography bold className="text-success">Aktiivinen {moment(activatedDate).format("D.M.YYYY")}</Typography>
      )
    }
  }
  
  const getTableRows = useCallback(() => {
    return rows && rows.map(row => {
      return [
        (
           <Typography>#{row?.id}</Typography>
        ),
        (
           <Link to={`/dashboard/users/edit/${row?.id}/`}><Typography>{row?.first_name} {row?.last_name}</Typography></Link>
        ),
        (
          <Typography>{row?.email}</Typography>
        ),
        (
          <Typography>{row?.phone}</Typography>
        ),
        (
          <Typography>{row?.is_staff ? "SuperAdmin" : "Puhelinkeskustyöntekijä"}</Typography>
        ),
        (
          <>{getStatus(row?.activated)}</>
        ),
        (
          <>
            <IconButton size="xl" iconName="settings-gear-65" onClick={() => handleEdit(row?.id)} />
          </>
        )
      ]
    })
  }, [rows])

  const getUsers = () => {
    setLoading(true);
    httpGetUsers(filters).then(res => {
      setRows(res);
    }).finally(response => {
      setLoading(false);
    })
  }

  useEffect(() => {
    getUsers()
  }, [])
  
  const handleFilterChange = ({ target }) => {
    const { value, name } = target;
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">

              <Row>
                <Col xs="6">
                  <Typography bold variant="h2" className="mb-0">Kaikki käyttäjät</Typography>
                  <Typography className="mb-0">Voit hallita täältä palvelutyöntekijöitä</Typography>
                </Col>
                <Col className="text-right" xs="6">
                { myUser?.is_staff && (
                  <>
                    <Button
                      className="btn-round btn-icon"
                      color="danger"
                      id="tooltip28070728"
                      onClick={handleAdd}
                      size="sm"
                    >
                      <span className="btn-inner--text">Lisää käyttäjä</span>
                    </Button>
                  </>
                )}
                </Col>
              </Row>

            
            {/*
            <Row className="mb-2">
              <Col>
                <Input name="search" onChange={handleFilterChange} value={filters?.search} onBlur={getUsers} placeholder="Hae nimellä" />
              </Col>
              <Col></Col>
              <Col></Col>
            </Row>
            */}
            
            <TableAuto
              checkboxes={false}
              headers={headers}
              rows={getTableRows()}
              loading={loading}
            />
          
          </div>
        </Row>
         
      </Container>
    </>
  );
}

export default CompanyUsers;
