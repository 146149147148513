import React from 'react';
import PropTypes from 'prop-types';
import { Table, THead, Pagination } from "stories/tables";
import { Checkbox } from "stories/forms";
import { Spinner } from "stories/components"

export const TableAuto = ({ 
    checkboxes,
    headers,
    rows,
    className,
    loading,
    pagination,
    paginationPrevious,
    paginationNext,
    paginationOffset,
    paginationLimit,
    paginationCount,
    ...rest 
  }) => {
  
  const renderHeader = () => {
    return headers && headers.map((header, index) => {
      return (
        <th key={index}>{header?.label}</th>
      )
    })
  }
  
  const renderRows = () => {
      
    return rows && rows.map((row, index) => {
      
      return (
        <tr key={index}>
          { checkboxes && (
            <th>
              <Checkbox />
            </th>
          )}
          {row && row.map((column, jindex) => (
            <td key={jindex} className={`${jindex+1 === row.length ? "text-right" : ""}`}>{column}</td>
          ))}
        </tr>
      )
    })
  }
  
  return (
    <>
      <Table
        className={`${className ? className : ""}`}
      >
        <THead>
          <tr>
            { checkboxes && (
              <th>
                <Checkbox />
              </th>
            )}
            {renderHeader()}
          </tr>
        </THead>
        <tbody>
        { loading ? (
          <tr><td><Spinner /></td></tr>
        ) : 
          renderRows()
        }
        </tbody>
      </Table>
      { pagination && !loading && (
        <Pagination 
          paginationPrevious={paginationPrevious}
          paginationNext={paginationNext}
          paginationOffset={paginationOffset}
          paginationLimit={paginationLimit}
          paginationCount={paginationCount}
        />
      )}
    </>
  )
};

TableAuto.propTypes = {
  headers: PropTypes.array,
  rows: PropTypes.array,
  checkboxes: PropTypes.bool,
  pagination: PropTypes.bool,
};

TableAuto.defaultProps = {
  checkboxes: false,
  pagination: false,
};

