import http, { authHeader } from './index'

const url = '/priima/calendars/'

export const httpGetCalendar = (params) => {
  console.log("httpGetCalendar");
  return http({
    url: `${url}`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    params,
  })
}

export const httpCreateCalendar = (data) => {
  console.log("httpCreateCalendar");
  return http({
    url: url,
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    data,
  })
}

/*
export const httpGetUser = (id) => {
  console.log("httpGetUser");
  return http({
    url: `${url}/${id}/`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
  })
}

export const httpCreateUser = (data) => {
  console.log("httpCreateUser");
  return http({
    url: url,
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    data,
  })
}

export const httpUpdateUser = (id, data) => {
  console.log("httpUpdateUser");
  return http({
     url: `${url}/${id}/`,
    method: 'PATCH',
    headers: {
      ...authHeader(),
    },
    data,
  })
}

export const httpDeleteUser = (id) => {
  console.log("httpDeleteUser");
  return http({
     url: `${url}/${id}/`,
    method: 'DELETE',
    headers: {
      ...authHeader(),
    },
  })
}
*/