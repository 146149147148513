import React from 'react';
import PropTypes from 'prop-types';
import { Button as ArgonButton } from "reactstrap";
import './button.css';

/**
 * Primary UI component for user interaction
 */
export const Button = ({ variant, children, ...rest }) => {
  // const mode = primary ? 'storybook-button--primary' : 'storybook-button--secondary';
  return (
    <ArgonButton
      type="button"
      color={variant}
      {...rest}
    >
      {children}
    </ArgonButton>
  );
};

Button.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  variant: PropTypes.oneOf(['primary','secondary','default','info','success','danger','warning']),
  /**
   * What background color to use
   */
  // backgroundColor: PropTypes.string,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['sm', 'normal', 'lg']),
  /**
   * Button contents
   */
  // label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Button.defaultProps = {
  variant: 'primary',
  size: 'normal',
  onClick: undefined,
};
