import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as BootstrapPagination, CardFooter, PaginationItem, PaginationLink} from "reactstrap";
import './Table.css';

export const Pagination = ({ 
    className, 
    paginationPrevious, 
    paginationNext, 
    paginationGoPage, 
    paginationLimit, 
    paginationOffset,
    paginationCount,
  }) => {
    
  const getCurrentPageNum = () => {
    if (paginationOffset === 0) {
      return 1;
    }
    return (paginationOffset / paginationLimit) + 1
  }
  
  const getMaxPageNum = () => {
    return Math.ceil(paginationCount / paginationLimit);
  }

  return (
    <CardFooter className="py-4">
      <nav aria-label="...">
        <BootstrapPagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <div class="d-flex flex-row">
            
            <div className="align-self-center">
            <PaginationItem className={paginationOffset === 0 ? "disabled" : ""}>
              <PaginationLink
                onClick={paginationPrevious}
                tabIndex="-1"
              >
                <i className="fas fa-angle-left" />
                <span className="sr-only">Previous</span>
              </PaginationLink>
            </PaginationItem>
            </div>
            {/*
            <PaginationItem className="active">
              <PaginationLink
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                1
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                2 <span className="sr-only">(current)</span>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                3
              </PaginationLink>
            </PaginationItem>
            */}
            <div className="align-self-center">
            <PaginationItem className={(paginationOffset > paginationCount - paginationLimit) ? "disabled" : ""}>
              <PaginationLink
                onClick={paginationNext}
              >
                <i className="fas fa-angle-right" />
                <span className="sr-only">Next</span>
              </PaginationLink>
            </PaginationItem>
            </div>
            <div className="align-self-center pl-2">
            <PaginationItem>
              {getCurrentPageNum()} / {getMaxPageNum()}
            </PaginationItem>
            </div>
          </div>
        </BootstrapPagination>
      </nav>
    </CardFooter>
  )
    
}

Pagination.defaultProps = {
  paginationLimit: 1,
  paginationOffset: 0,
  paginationCount: 0,
};

/* USAGE EXAMPLE

const paginationPrevious = () => {
  let offset = filters?.offset - filters?.limit;
  if (offset < 0) {
    offset = 0;
  }
  setFilters(f => ({
    ...f,
    offset: offset,
  }))
}

const paginationNext = () => {
  let offset = filters?.offset + filters?.limit;
  if (offset > filters?.count) {
    return
  }
  setFilters(f => ({
    ...f,
    offset: offset,
  }))
}

<TableAuto
  headers={headers}
  rows={getRows()}
  loading={loading}
  pagination={true}
  paginationPrevious={paginationPrevious}
  paginationNext={paginationNext}
/>


*/
