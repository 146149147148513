import React, { useState, useEffect } from "react";

import { Button, Typography, Modal } from "stories/components";
import { Container, Row, Col } from "stories/layout";
import MessageTemplate from "components/MessageTemplate/MessageTemplate"
import { httpGetMessageTemplates } from "services/messageTemplates"
import { httpGetCalendarMessageTemplates } from "services/messageTemplatesCalendar"
import { TableAuto } from "stories/tables";
const headers = [
  { label: "Viestipohja"},
  { label: ""},
];

const Settings = () => {
  
  const [loading, setLoading] = useState(false)
  
  const [modalOpen, setModalOpen] = useState(false)
  const [modalMessageTemplateObject, setModalMessageTemplateObject] = useState();
  
  const [chatTemplates, setChatTemplates] = useState([])
  const [calendarTemplates, setCalendarTemplates] = useState([])
  
  const getMessageTemplates = () => {
    setLoading(true);
    Promise.all([
      httpGetMessageTemplates().then(res => {
        setChatTemplates(res)
      }),
      httpGetCalendarMessageTemplates().then(res => {
        setCalendarTemplates(res)
      })
    ]).finally((response) => {
      setLoading(false);
    })
    
  }
  
  useEffect(() => {
    getMessageTemplates()
  }, [])
  
  const handleOpenAddModal = (type) => {
    setModalMessageTemplateObject({
      type: type,
      id: -1,
      message: "",
    });
    setModalOpen(true);
  }
  
  const handleOpenEditModal = (type, id, message) => {
    setModalMessageTemplateObject({
      type: type,
      id: id,
      message: message,
    });
    setModalOpen(true);
  }
  
  const handleModalCallback = () => {
    setModalOpen(false);
    getMessageTemplates()
  }
  
  const getChatTemplateRows = () => {
    return chatTemplates && chatTemplates.map(ct => {
      return [
        (<div style={{whiteSpace:'pre'}}>{ct.message}</div>),
        (<Button variant="secondary" onClick={() => handleOpenEditModal("chat", ct.id, ct.message)}>Muokkaa</Button>),
      ]
    })
  }
  
  const getCalendarTemplateRows = () => {
    return calendarTemplates && calendarTemplates.map(ct => {
      return [
        (<div style={{whiteSpace:'pre'}}>{ct.name}</div>),
        (<Button variant="secondary" onClick={() => handleOpenEditModal("calendar", ct.id, ct.name)}>Muokkaa</Button>),
      ]
    })
  }
  
  return (
    <>
      <Container fluid>
          <Row className="mt-3 mb-5">
            <Col xs="6">
              <Typography bold variant="h2" className="mb-0">Asetukset</Typography>
            </Col>
          </Row>
          
          <Container fluid>
          <Row className="mb-3">
            <Col>
              <Typography bold variant="h2" className="mb-0">Vakioviestiasetukset</Typography>
            </Col>
          </Row>
          
          <Row className="mt-5 mb-3">
            <Col>
              <Typography bold variant="h3" className="mb-0">Viestit</Typography>
            </Col>
          </Row>
          
          <TableAuto
            headers={headers}
            rows={getChatTemplateRows()}
            loading={loading}
          />
          
          <Row className="mb-3">
            <Col>
              <Button variant="secondary" onClick={() => handleOpenAddModal("chat")}>Uusi viestipohja</Button>
            </Col>
          </Row>
          
          <Row className="mt-5 mb-3">
            <Col>
              <Typography bold variant="h3" className="mb-0">Kalenteri</Typography>
            </Col>
          </Row>
          
          <TableAuto
            headers={headers}
            rows={getCalendarTemplateRows()}
            loading={loading}
          />
          
          <Row className="mb-3">
            <Col>
              <Button variant="secondary" onClick={() => handleOpenAddModal("calendar")}>Uusi viestipohja</Button>
            </Col>
          </Row>
          
          {/*
          <Row className="mb-3">
            <Col>
              <Button>Tallenna</Button>
            </Col>
          </Row>
          */}
          
        </Container>
        
      </Container>
      
      <Modal isOpen={modalOpen} toggleModal={() => setModalOpen(!modalOpen)} title={modalMessageTemplateObject?.id > -1 ? "Muokkaa viestipohjaa" : "Uusi viestipohja"}>
        <MessageTemplate messageTemplateObject={modalMessageTemplateObject} handleResolve={handleModalCallback} handleReject={handleModalCallback} />
      </Modal>
    </>
  );
}

export default Settings;
