import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import moment from 'moment'
import 'moment/locale/fi'  // without this line it didn't work

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
import "react-notification-alert/dist/animate.css";

// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

import appConfig from "services/config";
import "./services/i18n"
import Auth0ProviderWithHistory from "contexts/Auth0ProviderWithHistory";
import { Auth0Provider } from "@auth0/auth0-react";
import { StoreProvider } from "contexts/StoreContext";
import { AuthProvider } from "contexts/AuthContext";
import { NotificationProvider } from "stories/components/Notification"
import history from "services/history"

import App from "./app";

moment().locale('fi')

/*
<Auth0Provider
        domain={appConfig.auth0_domain}
        clientId={appConfig.auth0_clientId}
        redirectUri={window.location.origin}
        audience={appConfig.auth0_audience}
        cacheLocation="localstorage"
        useRefreshTokens={false}
      >
      
  </Auth0Provider>
*/

ReactDOM.render(
  <BrowserRouter>
    <StoreProvider>
      <Auth0ProviderWithHistory>
        <AuthProvider history={history}>
          <NotificationProvider>
            <App />
          </NotificationProvider>
        </AuthProvider>
      </Auth0ProviderWithHistory>
    </StoreProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
