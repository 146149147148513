import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import appConfig from "services/config";
import { setToken } from "services/token";
import { httpGetMyUser } from "services/companyUsers";

export const AuthContext = createContext({});

const AuthProviderComponent = ({ history, children }) => {
  console.log("process.env.REACT_APP_ENV", process.env.REACT_APP_ENV);
  const { isLoading, user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const [myUser, setMyUser] = useState();
  const [permissionLevel, setPermissionLevel] = useState(0);
  const [userOrganizationId, setUserOrganizationId] = useState();
  
  const getAccessToken = () => {
    if (isAuthenticated === true && isLoading === false) {
      getAccessTokenSilently({
          audience: appConfig.auth0_audience,
          scope: "read:current_user",
        }).then(res => {
          console.log("accessToken", res);
          setToken(res);
          setTimeout(() => {
            getMyUser();
          }, 100);
          
        }, error => {
          console.log("AuthContext:getAccessTokenSilently:error", error)
          // logout() // logout will refresh and cookies page will not stay
          // window.location.href = "/auth/cookies"
          history.push("/auth/cookies")
        });
    }
    else {
      // handleLogout()
    }
    
  }
  
  const handleLogout = () => {
    console.log("handleLogout");
    // logout({ returnTo: window.location.origin });
  }
  
  const getMyUser = () => {
    console.log("AuthContext:getMyUser");
    httpGetMyUser().then(response => {
      console.log("getMyUser response", response);
      setMyUser(response);
    }, error => {
      console.log("getMyUser error", error);
      window.alert("Käyttöoikeus päättynyt");
      logout({ returnTo: window.location.origin });
    })
  }
  
  useEffect(() => {
    console.log("useEffect isAuthenticated", isAuthenticated);
    getAccessToken();
  }, [isAuthenticated, isLoading]);
  
  const setUserPermissionLevel = () => {
    if (myUser?.is_staff === true) {
      setPermissionLevel(3)
    }
    else if (myUser?.organization_user_roles && myUser?.organization_user_roles.length > 0) {
      const role = myUser?.organization_user_roles[0]
      if (role?.status === 100) {
        setPermissionLevel(2)
      }
      else {
        setPermissionLevel(1)
      }
      setUserOrganizationId(role?.organization_fk)
    }
  }
  
  useEffect(() => {
    if (myUser) {
      setUserPermissionLevel()
    }
  }, [myUser])
  
  return (
      <AuthContext.Provider
        value={{
          myUser,
          getMyUser,
          permissionLevel,
          userOrganizationId,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
};
export const AuthProvider = withRouter(AuthProviderComponent);

AuthContext.defaultProps = {};
AuthContext.propTypes = {
  children: PropTypes.node.isRequired
};

export const useAuthContext = () =>
  useContext(AuthContext);
