import React, { useState, useCallback, useEffect } from "react";

import { Input, Checkbox, Toggle, Dropdown, Select } from "stories/forms";
import { httpGetOrganizations } from "services/organizations"

const UserStatusSelect = (props) => {
  
  const {
    name,
    value,
    setFieldValue,
    isDisabled=false,
  } = props;
  
  const [loading, setLoading] = useState(false)
  const [objects, setObjects] = useState([
    { id: 1, name: "Peruskäyttäjä" },
    { id: 100, name: "Pääkäyttäjä" },
  ])
  
  const getItems = () => {
    return objects && objects.map(obj => {
      return {
        label: obj.name.slice(0,32),
        value: obj.id,
      }
    })
  }
  
  const getDefaultValue = () => {
    
    const defaultValue = objects && objects.reduce((result, obj) => {
      if (obj.id === value) { result = obj }
      return result
    }, null)
    
    if (defaultValue) {
      return {
        label: defaultValue?.name, 
        value: value
      }
    }
    return null
  }
  
  if (!objects) {
    return null;
  }
  
  return (
    <Select
      isDisabled={isDisabled}
      name={name}
      label="Rooli" 
      isMulti={false}
      closeMenuOnSelect={true}
      options={getItems()}
      defaultValue={getDefaultValue()}
      value={value}
      setFieldValue={setFieldValue} 
    />
  )

}

export default UserStatusSelect