import React from 'react';

import {default as ReactSelect} from 'react-select';

import MenuList from "./MenuList";
import Option from "./Option";

import {
  FormGroup,
} from "reactstrap";

import { Typography } from "stories/components"
import "./Select.css"

const CustomClearText = () => 'tyhjennä';
const ClearIndicator = props => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <div style={{ padding: '0px 5px' }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: 'pointer',
  color: state.isFocused ? 'black' : 'black',
});

export default function Select({showAllOption=false, isMulti=true, closeMenuOnSelect=false, errorText, focused, label, options, required, value, setFieldValue, ...rest}) {
  
  const allOption = {value: '', label: "Kaikki"}
  
  const handleSelectChange = (obj, child) => {
    if (child?.action === "select-option") {
      if (isMulti) {
        setFieldValue(child.name, [
          ...value,
          {id:child.option?.value, name:child.option?.label}
        ])
      }
      else {
        setFieldValue(child.name, obj?.value)
      }
    }
    else if (child?.action === "remove-value") {
      if (isMulti) {
        let newValues = [
          ...value
        ]
        const index = newValues.findIndex(obj => obj.id == child?.removedValue?.value)
        newValues.splice(index, 1)
        setFieldValue(child.name, newValues)
      }
      else {
        setFieldValue(child.name, "")
      }
    }
    else if (child?.action === "clear") {
      if (isMulti) {
        setFieldValue(child.name, [])
      }
      else {
        setFieldValue(child.name, "")
      }
    }
  }
  
  const getOptions = () => {
    if (showAllOption === true) {
    return [allOption, ...options]
    }
    else {
      return options
    }
  }
  
  return (
  <>
    <FormGroup
        className={`${focused ? "focused" : ""} mb-2`}
      >
        {label && (
          <Typography bold className={`labelInline ? "d-inline" : ""`}>{label} {required ? "*" : ""}</Typography>
        )}
        
        <ReactSelect
          {...rest}
          closeMenuOnSelect={closeMenuOnSelect}
          className="select_multi_select"
          components={{ ClearIndicator }}
          menuPortalTarget={document.body} 
          styles={{ 
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            valueContainer: base => ({ ...base, paddingTop: 5, paddingBottom: 5 })
          }}
          /*
            components={{ ClearIndicator }}
            styles={{ clearIndicator: ClearIndicatorStyles }}
          */
          isMulti={isMulti}
          options={getOptions()}
          placeholder="Valitse..."
          noOptionsMessage={() => function(){ return "No choices"}}
          onChange={handleSelectChange}
          components={{
            MenuList,
            Option
          }}
        />
        
        { errorText && (
        <Typography className="text-danger">{errorText}</Typography>
        )}
    </FormGroup>
  
  </>
  );
}

Select.defaultProps = {
  menuPlacement: "auto",
}

/*
export const exampleOptions = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
];
*/

/* Example */

/*
import React, { useState, useCallback } from "react";

import { Select } from "stories/forms";
import { httpGetOrganizations } from "services/organizations"

const OrganizationSelect = (props) => {
  
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState()
  const [objects, setObjects] = useState([
    {id: 1, name: "AAA"},
    {id: 2, name: "BBB"},
  ])
  
  const getOrganizations = () => {
    setLoading(true);
    const filters = {}
    httpGetOrganizations(filters).then(res => {
      setObjects(res);
    }).finally(response => {
      setLoading(false);
    })
  }
  
  useEffect(() => {
    getOrganizations()
  }, [])
  
  const setFieldValue = (name, value) => {
    setSelected(value)
  }
  
  
  const getItems = () => {
    return objects && objects.map(obj => {
      return {
        label: obj.name.slice(0,16),
        value: obj.id,
      }
    })
  }
  
  return (
    <Select 
      label="Organisaatio" 
      isMulti={false}
      closeMenuOnSelect={true}
      options={getItems()} 
      value={selected} 
      setFieldValue={setFieldValue} 
    />
  )
}

export default OrganizationSelect
*/