import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "Test": "Test",
    }
  },
  fi: {
    translation: {
      "Admin User": "Yrityskäyttäjä",
      "Priima User": "Asiakaskäyttäjä",
      "Login": "Kirjautuminen",
      "Chat message": "Chat viesti",
      "Create": "Luonti",
      "Update": "Muokkaus",
      "Delete": "Poisto",
      "Reverse": "Peruutus",
      "Calendar event": "Kalenteritapahtuma",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fi",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;