import React from "react";

import { Typography, Link } from "stories/components";
import { Container, Row, Col } from "stories/layout";

const Help = () => {
  
  return (
    <>
      <Container fluid>
        <Row className="mt-3 mb-5">
          <Col xs="6">
            <Typography bold variant="h2" className="mb-0">Ohjeet</Typography>
          </Col>
        </Row>
        
        <Row className="mb-3">
          <Col>
            <Link isExternal href="https://priima-avustaja.fi/">
              <Typography className="mb-0">Priima Avustaja</Typography>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Help;
