import http, { authHeader } from './index'

const url = '/priima/chat_message_templates/'

export const httpGetMessageTemplates = (params) => {
  return http({
    url: `${url}`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    params,
  })
}

export const httpCreateMessageTemplate = (data) => {
  return http({
    url: url,
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    data,
  })
}

export const httpUpdateMessageTemplate = (id, data) => {
  return http({
    url: `${url}${id}/` ,
    method: 'PATCH',
    headers: {
      ...authHeader(),
    },
    data,
  })
}

export const httpDeleteMessageTemplate = (id) => {
  return http({
    url: `${url}${id}/` ,
    method: 'DELETE',
    headers: {
      ...authHeader(),
    },
  })
}