import React, { useEffect, useState, useCallback }from "react";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Card, Typography, IconButton, Modal } from "stories/components";
import { Container, Row, Col } from "stories/layout";
import { TableAuto } from "stories/tables";
import { httpGetUserLogs, httpReverseUserLog } from "services/logs";
import { useStoreContext } from "contexts/StoreContext"
import { useNotification } from "stories/components/Notification"
import { httpGetChatMessage } from "services/chatMessages"
import { httpGetCalendarEvent } from "services/calendarEvents"

const headers = [
  { label: "Aikaleima"},
  { label: "Käyttäjä"},
  { label: "Vastaanottaja"},
  { label: "Tyyppi"},
  { label: "Toimenpide"},
  { label: ""},
];

const Log = () => {
  const { t } = useTranslation();
  const { enums } = useStoreContext();
  const { notify } = useNotification();
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState({
    offset: 0,
    limit: 20,
  })
  const [logs, setLogs] = useState({
    count: 0,
    results: [],
  })
  // Content Modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalObject, setModalObject] = useState({
    key: -1,
    type: "",
    content: "",
  });
  
  const getLogs = () => {
    setLoading(true);
    httpGetUserLogs(filters).then(res => {
      setLogs(res);
    }).finally(()=>{
      setLoading(false);
    })
  }
  useEffect(() => {
    getLogs();
  }, [])
  
  useEffect(() => {
    getLogs();
  }, [filters])
  
  const getType = useCallback((typeKey) => {
    return enums && enums?.users?.my_user_log?.type.map(obj => {
      if (obj.key === typeKey) {
        return t(obj.value)
      }
    })
  }, [enums, logs])
  
  const getOperation = useCallback((operationKey) => {
    return enums && enums?.users?.my_user_log?.operation.map(obj => {
      if (obj.key === operationKey) {
        return t(obj.value)
      }
    })
  }, [enums, logs])
  
  const handleView = (logObject) => {
    if (logObject?.type === 3) { // Chat
      httpGetChatMessage(logObject?.object_id).then(res=>{
        setModalOpen(true);
        setModalObject(s => ({
          ...s,
          content: res?.message,
        }))
      }, error => {
        setModalOpen(true);
        setModalObject(s => ({
          ...s,
          content: `Chat viestiä ei löytynyt`,
        }))
      })
    }
    else if (logObject?.type === 4) { // Calendar
      httpGetCalendarEvent(logObject?.object_id).then(res=>{
        setModalOpen(true);
        setModalObject(s => ({
          ...s,
          content: `${res?.name}\n${res?.description}`,
        }))
      }, error => {
        setModalOpen(true);
        setModalObject(s => ({
          ...s,
          content: `Kalenteritapahtumaa ei löytynyt`,
        }))
      })
    }
    
  }
  
  const handleReverse = (id) => {
    let result = window.confirm("Peruutetaanko tehty toiminto?");
    
    if (result) {
      httpReverseUserLog(id).then(res => {
        notify({ title:"Toiminto", message:"Peruutus onnistui"})
        getLogs();
      }, error => {
        notify({ title:"Toiminto", message:"Peruutus epäonnistui"})
      })
    }
  }
  
  const getRows = useCallback(() => {
    return logs && logs?.results?.map((log, i) => {
      return [
        (
          <Typography>{moment(log.created).format("D.M.YYYY HH:mm")}</Typography>
        ),
        (
          <Typography>{log?.user?.first_name} {log?.user?.last_name}</Typography>
        ),
        (
          <Typography>{log?.receiver}</Typography>
        ),
        (
          <Typography>{getType(log?.type)}</Typography>
        ),
        (
          <Typography>{getOperation(log?.operation)}</Typography>
        ),
        (
          <>
            { (!log?.reversed && ![4,5].includes(log?.operation)) ? (
              <>
              { [3,4].includes(log?.type) && ![3].includes(log?.operation) && (
                <Button variant="default" onClick={() => handleView(log)}>Näytä</Button>
              )}
              <Button variant="danger" onClick={() => handleReverse(log?.id)}>Peruuta</Button>
              </>
            ) : null}
            
            {log?.reversed && (
              <Typography>Peruutettu</Typography>
            )}
          </>
        ),
      ]
    })
  }, [logs, enums])
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            
              <Row>
                <Col xs="6">
                  <Typography bold variant="h2" className="mb-0">Viimeisimmät toiminnot</Typography>
                  <Typography className="mb-0">Voit poistaa 30vrk vanhoja tapahtumia</Typography>
                </Col>
                <Col className="text-right" xs="6">
                </Col>
              </Row>
              
              <TableAuto
                checkboxes={false}
                headers={headers}
                rows={getRows()}
                loading={loading}
                pagination={true}
                paginationPrevious={paginationPrevious}
                paginationNext={paginationNext}
                paginationOffset={filters?.offset}
                paginationLimit={filters?.limit}
                paginationCount={logs?.count}
              />
 
          </div>
        </Row>
        
        {/* size="modal-lg"  */}
        
        <Modal isOpen={modalOpen} toggleModal={() => setModalOpen(s => !s)} title={`Sisältö`}>
          <div >{modalObject?.content}</div>
        </Modal>
      
      </Container>
    </>
  );
}

export default Log;
