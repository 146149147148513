import React from 'react';
import PropTypes from 'prop-types';
import { Table as BootstrapTable } from "reactstrap";
import './Table.css';

export const Table = ({ hover, responsive, className, children, ...rest }) => {
  
  let opts = {};
  if (hover) {
    opts["hover"]=true;
  }
  if (responsive) {
    opts["responsive"]=true;
  }
  
  return (
    <BootstrapTable
      className={`align-items-center table-flush ${className ? className : ""}`}
      {...opts}
      {...rest}
    >
    {children}
    </BootstrapTable>
  )
};

Table.propTypes = {
  hover: PropTypes.bool,
  responsive: PropTypes.bool,
};

Table.defaultProps = {
  hover: true,
  responsive: true,
};

