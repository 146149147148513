import React from 'react';
import PropTypes from 'prop-types';

export const Spinner = ({ color, className }) => {
  return (
    <div className={`spinner-border ${className} ${color}`} role="status">
      <span className="sr-only">Ladataan...</span>
    </div>
  );
};

Spinner.propTypes = {
  
};

Spinner.defaultProps = {
  className: "",
  color: "text-primary",
};
