import React, { useCallback } from "react";
import moment from "moment";

import { Button, Card, Typography, IconButton, Spinner } from "stories/components";
import { Container, Row, Col } from "stories/layout";

const CustomerInfo = (props) => {
  
  const {
    customer,
   } = props;
   
  const getStatus = (activatedDate) => {
    
    if (!activatedDate || moment(activatedDate).isBefore(moment())) {
      return (
        <Typography bold className="text-danger">Deaktiivinen</Typography>
      )
    }
    else {
      return (
        <Typography bold className="text-success">Aktiivinen {moment(activatedDate).format("D.M.YYYY")}</Typography>
      )
    }
  }
   
  return (
    <>
      <Row>
        <Col className="mb-2">
          <Typography>{customer?.firstname} {customer?.lastname}</Typography>
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <Typography>{customer?.email}</Typography>
        </Col>
      </Row>
      {customer?.phone && (
      <Row>
        <Col className="mb-2">
          <Typography>{customer?.phone}</Typography>
        </Col>
      </Row>
      )}
      <Row>
        <Col className="mb-2">
        {getStatus(customer?.active)}
        </Col>
      </Row>
    </>
  );
}

export default CustomerInfo;