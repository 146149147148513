import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer } from "stories/layout";
import { Input } from "stories/forms";

import { httpGetUser, httpUpdateUser, httpDeleteUser } from "services/companyUsers"
import { useNotification } from "stories/components/Notification"
import { useAuthContext }from "contexts/AuthContext"

import { httpCreateOrganizationUserRole, httpUpdateOrganizationUserRole } from "services/organizations"
import OrganizationSelect from "components/OrganizationSelect/OrganizationSelect"
import OrganizationUnitSelect from "components/OrganizationUnitSelect/OrganizationUnitSelect"

require('yup-phone');

const CompanyUserEdit = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm
   } = props;
   
   const {
      history,
      isStaff,
      myUser,
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    if (isStaff) {
      history.push("/dashboard/users");
    }
    else {
      history.push("/dashboard/myuser");
    }
  }
  
  const handleDelete = () => {
    const result = window.confirm("Haluatko varmasti poistaa käyttäjän?");
    if (result) {
      httpDeleteUser(values?.id).then(res => {
        if (isStaff) {
          history.push("/dashboard/users");
        }
        else {
          history.push("/dashboard/myuser");
        }
      })
    }
  }
  
  const getActiveDaysLeft = (currentActiveDate) => {
    if (!currentActiveDate) {
      return "";
    }
    const now = moment().set({hour:0,minute:0,second:0,millisecond:0})
    const currentActiveDateMoment = moment(currentActiveDate);
    return currentActiveDateMoment.diff(now, 'days')
  }
  
  const handleSendInvitation = () => {
    
  }
  
  return (
    <>
      <FormContainer>
        
        <Row>
          <Col className="mb-3">
            <Typography variant="h2" bold>Muokkaa käyttäjää</Typography>
          </Col>
          {/*
          <Col>
            <Button onClick={handleSendInvitation} variant="success">Lähetä kutsu</Button>
          </Col>
          */}
        </Row>
        
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("first_name")}
              label="Etunimi"
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("last_name")}
              label="Sukunimi"
            />
          </Col>
        </Row>
        { isStaff && (
          <>
            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("email")}
                  type="email"
                  label="Sähköposti"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("phone")}
                  label="Puhelin"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("activated")}
                  label="Voimassa saakka"
                  defaultValue={values.activatedDefaultValue}
                  type="date"
                  required
                />
                { values.activated && (
                  <Typography variant="small">{getActiveDaysLeft(values.activated)} päivää jäljellä</Typography>
                )}
              </Col>
            </Row>
            
          </>
        )}
        
        { values?.is_staff ? (
          <div className="mb-2">Käyttäjällä on SuperAdmin rooli. Pääsy kaikkiin organisaatioihin.</div>
        ) : (
          <>
            <Row>
              <Col className="mb-3">
                <OrganizationSelect 
                  {...getFormikInputProps("organization_fk")} 
                  setFieldValue={setFieldValue}
                  isDisabled={values?.id == myUser?.id}
                />
              </Col>
            </Row>
            
            <Row>
              <Col className="mb-3">
                <OrganizationUnitSelect 
                  {...getFormikInputProps("organization_unit_fk")} 
                  setFieldValue={setFieldValue} 
                  organizationId={values?.organization_fk}
                  isDisabled={values?.id == myUser?.id}
                />
                  
              </Col>
            </Row>
          </>
        )}
        
        <Row>
          <Col className="mb-3">
            <Button onClick={handleCancel} variant="secondary">Peruuta</Button>
          </Col>
          { values?.is_staff === false && isStaff ? (
          <Col className="mb-3 text-center">
            <Button onClick={handleDelete} variant="danger">Poista</Button>
          </Col>
          ) : null}
          <Col className="mb-3 text-right">
            <Button disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>Tallenna</Button>
            { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
          </Col>
        </Row>
        
      </FormContainer>
    </>
  );
}

const defaultValues = {
  id: -1,
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  activated: moment().set({hour:23,minute:59,second:59,millisecond:999}).format("YYYY-MM-DD"),
}

 const CompanyUserEditFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },
    validationSchema: props => {
      const required = "Kenttä on pakollinen";
      if (props.isStaff) {
        return Yup.object().shape({
          first_name: Yup.string().nullable(),
          last_name: Yup.string().nullable(),
          email: Yup.string().email('Tarkista sähköpostiosoitteen muoto').required(required),
          phone: Yup.string().phone("FI", false,'Puhelinnumero tulee olla muodossa esim. +358501234567').required(required).nullable(),
          activated: Yup.date().required(required),
        });
      }
      else {
        return Yup.object().shape({
          first_name: Yup.string().nullable(),
          last_name: Yup.string().nullable(),
        });
      }
      
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      let data = {};
      if (props.isStaff) {
        data = {
          ...values,
          activated: values.activated ? moment(values.activated).set({hour:23,minute:59,second:59,millisecond:999}) : null,
        }
      }
      else {
        data = {
          first_name: values?.first_name,
          last_name: values?.last_name,
        }
      }
      
      httpUpdateUser(values.id, data).then(res => {
        
        if (values?.is_staff == false) {
          if (values?.organization_user_role_id) {
            const roleData = {
              organization_fk: values?.organization_fk,
              organization_unit_fk: values?.organization_unit_fk,
              user_fk: values?.id,
              status: values?.organization_user_role_status,
            }
            httpUpdateOrganizationUserRole(values?.organization_user_role_id, roleData).then(res2 => {
              setSubmitting(false);
              props.notify({ title:"Käyttäjä", message:"Tallennettu onnistuneesti"})
            })
          }
          else {
            const roleData = {
              organization_fk: values?.organization_fk,
              organization_unit_fk: values?.organization_unit_fk,
              user_fk: values?.id,
              status: values?.organization_user_role_status,
            }
            httpCreateOrganizationUserRole(roleData).then(res2 => {
              setSubmitting(false);
              props.notify({ title:"Käyttäjä", message:"Tallennettu onnistuneesti"})
            })
          }
        }
        else {
          setSubmitting(false);
          props.notify({ title:"Käyttäjä", message:"Tallennettu onnistuneesti"})
        }
      }, error => {
        setSubmitting(false);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          })
        }
        else {
          setErrors({
            general: "Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen."
          })
        }
      })
    },
    displayName: "BasicForm"
  
  })(CompanyUserEdit)
    
    
const CompanyUserEditView = ({match, history}) => {
  // API requests here
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(null);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const { myUser } = useAuthContext()
  
  const getUser = (id) => {
    setLoading(true)
    httpGetUser(id).then(res => {
      
      let organizationUserRole;
      if (res?.organization_user_roles && res?.organization_user_roles.length > 0) {
        organizationUserRole = res?.organization_user_roles[0]
      }
      
      setPreSetValues({
        ...defaultValues,
        ...res,
        activated:res?.activated ? moment(res?.activated).format("YYYY-MM-DD") + "" : null,
        activatedDefaultValue:res?.activated ? moment(res?.activated).format("YYYY-MM-DD") + "" : null,
        organization_user_role_id: organizationUserRole ? organizationUserRole?.id : null,
        organization_fk: organizationUserRole ? organizationUserRole?.organization_fk : null,
        organization_unit_fk: organizationUserRole ? organizationUserRole?.organization_unit_fk : null,
        organization_user_role_status: organizationUserRole ? organizationUserRole?.status : null,
        
      });
    }).finally(response => {
      setLoading(false);
    })
  }
  
  useEffect(() => {
    getUser(match?.params?.id)
  }, [match])
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }
  
  if (preSetValues) {
    return (
      <CompanyUserEditFormik 
        history={history} 
        preSetValues={preSetValues} 
        notify={notify} 
        isStaff={myUser?.is_staff} 
        myUser={myUser}
      />
    )
  }
  return null;
}
  

export default CompanyUserEditView;
