import React from 'react';
import PropTypes from 'prop-types';
import './THead.css';

export const THead = ({ className, children, ...rest }) => {
  
  let opts = {};
  
  return (
    <thead
      className={`thead-light ${className ? className : ""}`}
      {...opts}
      {...rest}
    >
    {children}
    </thead>
  )
};

THead.propTypes = {
  
};

THead.defaultProps = {
  
};

