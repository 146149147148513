import http, { authHeader } from './index'

const url = '/user_logs/'

export const httpGetUserLogs = (params) => {
  console.log("httpGetUserLogs");
  return http({
    url: `${url}`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    params,
  })
}

export const httpReverseUserLog = (id) => {
  console.log("httpReverseUserLog");
  return http({
    url: `${url}${id}/reverse/`,
    method: 'POST',
    headers: {
      ...authHeader(),
    },
  })
}