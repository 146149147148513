import http, { authHeader } from './index'

const url = '/priima/calendar_events/'

export const httpGetCalendarEvents = (params) => {
  console.log("httpGetCalendarEvents");
  return http({
    url: `${url}`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    params,
  })
}

export const httpGetCalendarEvent = (eventKey) => {
  console.log("httpGetCalendarEvent");
  return http({
    url: `${url}${eventKey}/`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
  })
}

export const httpCreateCalendarEvent = (data) => {
  console.log("httpCreateCalendarEvent");
  return http({
    url: url,
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    data,
  })
}

export const httpUpdateCalendarEvent = (eventKey, data) => {
  console.log("httpUpdateCalendarEvent");
  return http({
    url: `${url}${eventKey}/`,
    method: 'PATCH',
    headers: {
      ...authHeader(),
    },
    data,
  })
}

export const httpDeleteCalendarEvent = (eventKey) => {
  console.log("httpUpdateCalendarEvent");
  return http({
    url: `${url}${eventKey}/`,
    method: 'DELETE',
    headers: {
      ...authHeader(),
    },
  })
}



/*
export const httpGetUser = (id) => {
  console.log("httpGetUser");
  return http({
    url: `${url}/${id}/`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
  })
}

export const httpCreateUser = (data) => {
  console.log("httpCreateUser");
  return http({
    url: url,
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    data,
  })
}

export const httpUpdateUser = (id, data) => {
  console.log("httpUpdateUser");
  return http({
     url: `${url}/${id}/`,
    method: 'PATCH',
    headers: {
      ...authHeader(),
    },
    data,
  })
}

export const httpDeleteUser = (id) => {
  console.log("httpDeleteUser");
  return http({
     url: `${url}/${id}/`,
    method: 'DELETE',
    headers: {
      ...authHeader(),
    },
  })
}
*/