import React from "react";
import moment from "moment";

import { Container, Row, Col } from "stories/layout";
import { Card, Typography, Button, Link } from "stories/components";
import { useAuthContext }from "contexts/AuthContext"

const FrontPage = () => {
  
  const { myUser } = useAuthContext()
  
  return (
    <>
    { myUser && (
      <Container fluid>
        <Row>
          <div className="col">

            <Row>
              <Col>
                <Typography bold variant="h2" className="mb-0">Tervetuloa!</Typography>
                <Typography className="mb-0">Olet kirjautunut viimeksi {myUser?.last_login ? moment(myUser?.last_login).format("D.M.YYYY HH:mm") : moment().format("D.M.YYYY HH:mm")}</Typography>
              </Col>
            </Row>

            <Row>
              <Col className="mb-2"><Link to="/dashboard/customers"><Button className="p-5" color="default" block>Avaa asiakaskäyttäjät</Button></Link></Col>
              <Col className="mb-2"><Link to="/dashboard/myuser"><Button className="p-5" color="default" block >Näytä omat tiedot</Button></Link></Col>
              { myUser?.is_staff && (
                <Col className="mb-2"><Link to="/dashboard/log"><Button className="p-5" color="default" block >Näytä loki</Button></Link></Col>
              )}
            </Row>
            
          </div>
        </Row>
      </Container>
        
    
    
    )}
    </>
  );
}

export default FrontPage;
