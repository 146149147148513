import React, { useEffect, useState, useCallback } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"

// Stories
import { Button, Typography, Link, Modal, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer } from "stories/layout";
import { Input } from "stories/forms";
import { useNotification } from "stories/components/Notification"

// Services
import { 
  httpCreateOrganizationUserInvitation,
  httpSendOrganizationUserInvitation
} from "services/organizations";

import { useAuthContext }from "contexts/AuthContext"
import { useStoreContext } from "contexts/StoreContext"
import { httpGetUser, httpCreateUser, httpUpdateUser, httpDeleteUser } from "services/companyUsers"

// App
import OrganizationSelect from "components/OrganizationSelect/OrganizationSelect"
import OrganizationUnitSelect from "components/OrganizationUnitSelect/OrganizationUnitSelect"
import UserStatusSelect from "components/UserStatusSelect/UserStatusSelect"


require('yup-phone');

function OrganizationUserInvite(props) {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     validateForm,
     setFieldValue,
     setTouched,
     setErrors,
   } = props;
   
   const {
      history,
      preSetValues,
      inModal,
      handleResolve,
      handleReject,
      isStaff,
      handleSendInvitation,
   } = props;
   
   const { enums } = useStoreContext();
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    if (inModal) {
      handleReject();
    }
    else {
      history.push("/dashboard/organizations/users");
    }
  }
  
  const handleDelete = () => {
    const result = window.confirm("Haluatko varmasti poistaa käyttäjän?");
    if (result) {
      httpDeleteUser(values?.id).then(res => {
        if (isStaff) {
          history.push("/dashboard/users");
        }
        else {
          history.push("/dashboard/myuser");
        }
      })
    }
  }
  
  const getActiveDaysLeft = (currentActiveDate) => {
    if (!currentActiveDate) {
      return "";
    }
    const now = moment().set({hour:0,minute:0,second:0,millisecond:0})
    const currentActiveDateMoment = moment(currentActiveDate);
    return currentActiveDateMoment.diff(now, 'days')
  }
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors(res);
      }
    })
  }

  
  return (
    <>
        <form onSubmit={onSubmit}>
        <Row>
          <Col className="mb-3">
          {values?.id ? (
            <Typography variant="h2" bold>Muokkaa organisaatio käyttäjää</Typography>
          ) : (
            <Typography variant="h2" bold>Luo organisaatio käyttäjä</Typography>
          )}
          </Col>
          {/*
          <Col className="text-right">
          { values?.user_role_id ? (
            <Button onClick={handleSendInvitation} variant="success">Lähetä kutsu</Button>
          ) : null}
          </Col>
          */}
        </Row>
        
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("first_name")}
              label="Etunimi"
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("last_name")}
              label="Sukunimi"
            />
          </Col>
        </Row>
        
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("email")}
              type="email"
              label="Sähköposti"
              required
            />
          </Col>
        </Row>
        
        {/*
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("activated")}
              label="Voimassa saakka"
              defaultValue={values.activatedDefaultValue}
              type="date"
              required
            />
            { values.activated && (
              <Typography variant="small">{getActiveDaysLeft(values.activated)} päivää jäljellä</Typography>
            )}
          </Col>
        </Row>
        */}
        
        <Row>
          <Col className="mb-3">
            <OrganizationSelect 
              {...getFormikInputProps("organization_fk")} 
              setFieldValue={setFieldValue} 
            />
          </Col>
        </Row>
        
        <Row>
          <Col className="mb-3">
            <OrganizationUnitSelect 
              {...getFormikInputProps("organization_unit_fk")} 
              setFieldValue={setFieldValue} 
              organizationId={values?.organization_fk} 
            />
          </Col>
        </Row>
        
        <Row>
          <Col className="mb-3">
            <UserStatusSelect 
              {...getFormikInputProps("status")} 
              setFieldValue={setFieldValue} 
            />
          </Col>
        </Row>
        
        
        <Row>
          <Col className="mb-3">
            <Button onClick={handleCancel} variant="secondary">Peruuta</Button>
          </Col>
          { values?.is_staff === false && isStaff ? (
          <Col className="mb-3 text-center">
            <Button onClick={handleDelete} variant="danger">Poista</Button>
          </Col>
          ) : null}
          <Col className="mb-3 text-right">
            <Button disabled={isSubmitting} type="submit">Luo ja lähetä kutsu</Button>
            { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
          </Col>
        </Row>
        </form>
    </>
  );
}

 const OrganizationUserInviteFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          id: 0,
        };
      }
    },
    validationSchema: props => {
      const required = "Kenttä on pakollinen";
      return Yup.object().shape({
        first_name: Yup.string().nullable().required(required),
        last_name: Yup.string().nullable().required(required),
        email: Yup.string().nullable().required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {
        handleResolve,
        history,
        inModal,
      } = props;
      const required = "Kenttä on pakollinen";
      
      const data = {
        ...values,
        activated: values.activated ? moment(values.activated).set({hour:23,minute:59,second:59,millisecond:999}) : null,
      }
      httpCreateOrganizationUserInvitation(data).then(createResponse => {
          console.log("httpCreateOrganizationUserInvitation.createResponse", createResponse)
          const organizationUserInvitationId = createResponse?.id
          httpSendOrganizationUserInvitation(organizationUserInvitationId).then(sendResponse => {
            if (inModal) {
              handleResolve();
            }
            else {
              history.push("/dashboard/organizations/users");
            }
            
          }, error => {
            setSubmitting(false);
            setErrors({
              ...error?.data?.detail,
              general: error?.data?.detail,
            })
          })
      }, error => {
        setSubmitting(false);
        setErrors({
          ...error?.data?.detail,
          general: error?.data?.detail,
        })
      })
        
        
    },
    displayName: "BasicForm"
  
  })(OrganizationUserInvite)
    
    
const OrganizationUserInviteView = ({match, inModal, history, handleResolve, ...rest}) => {
  // API requests here
  const [loading, setLoading] = useState(false);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [preSetValues, setPreSetValues] = useState(null);
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }
  
  return (
    <OrganizationUserInviteFormik 
      preSetValues={preSetValues} 
      notify={notify} 
      inModal={inModal} 
      history={history}
      handleResolve={handleResolve}
      {...rest} 
    />
  )
}

OrganizationUserInviteView.defaultProps = {
  inModal: false,
}
  
export default OrganizationUserInviteView;
