let config = {
  auth0_domain: 'priima.eu.auth0.com',
  auth0_clientId: 'vaYo6xHLokhWVeARt3c9uDGQJEyIYZhQ',
  auth0_audience: 'https://test/api',
  // apiUrl: 'https://appdev.priima-avustaja.fi/pwa_api/',
  // apiUrl: 'https://secure.priima-avustaja.fi/api/',
  apiUrl: 'http://localhost:8000/api/',
};

if (process?.env?.REACT_APP_ENV === "prod") {
  config = {
    auth0_domain: 'priima.eu.auth0.com',
    auth0_clientId: 'VCSgF47Lp8guYack7vkKfQBYrZ7l7i72',
    auth0_audience: 'https://test/api',
    apiUrl: 'https://secure.priima-avustaja.fi/api/',
  }
}


export default config;