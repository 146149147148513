import React, { useCallback } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";

import { Button, Typography, IconButton } from "stories/components";
import { Container, Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { httpCreateMessageTemplate, httpUpdateMessageTemplate, httpDeleteMessageTemplate } from "services/messageTemplates"
import { httpCreateCalendarMessageTemplate, httpUpdateCalendarMessageTemplate, httpDeleteCalendarMessageTemplate } from "services/messageTemplatesCalendar"
import { useNotification } from "stories/components/Notification"

function MessageTemplateForm(props) {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid
   } = props;
   
   const {
      eventDate,
      handleResolve,
      handleReject,
      notify
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
   const handleClose = () => {
    handleReject();
  }
  
  const handleAddTag = (tagName) => {
    setFieldValue("message", `${values["message"]} {${tagName}}`)
  }
  
  const handleDelete = () => {
    let result = window.confirm("Poistetaanko?");
    if (result) {
      if (values?.type === "chat") {
        httpDeleteMessageTemplate(values?.id).then(res => {
          notify({ title:"Viestipohja", message:"Poistettu onnistuneesti"})
          handleResolve();
        })
      }
      else {
        httpDeleteCalendarMessageTemplate(values?.id).then(res => {
          notify({ title:"Viestipohja", message:"Poistettu onnistuneesti"})
          handleResolve();
        })
      }
    }
    
  }
  
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("message")}
              label="Sisältö"
              value={values.message}
              type="textarea"
            />
          </Col>
        </Row>
        
        { values && values?.type === "calendar" && (
        <Row>
          <Col className="mb-3">
            <Button size="sm" variant="secondary" onClick={() => handleAddTag("date")}>
              <i className="ni ni-tag" style={{verticalAlign:"middle"}}></i> Päivämäärä
            </Button>
          </Col>
          <Col className="mb-3">
            <Button size="sm" variant="secondary" onClick={() => handleAddTag("starttime")}>
              <i className="ni ni-tag" style={{verticalAlign:"middle"}}></i> Aloitusaika
            </Button>
          </Col>
          <Col className="mb-3">
            <Button size="sm" variant="secondary" onClick={() => handleAddTag("weekday")}>
              <i className="ni ni-tag" style={{verticalAlign:"middle"}}></i> Viikonpäivä
            </Button>
          </Col>
          <Col className="mb-3">
            <Button size="sm" variant="secondary" onClick={() => handleAddTag("eventname")}>
              <i className="ni ni-tag" style={{verticalAlign:"middle"}}></i> Tapahtuma
            </Button>
          </Col>
          <Col className="mb-3">
            <Button size="sm" variant="secondary" onClick={() => handleAddTag("firstname")}>
              <i className="ni ni-tag" style={{verticalAlign:"middle"}}></i> Asp:n etunimi
            </Button>
          </Col>
          <Col></Col>
        </Row>
        )}
        
        <Row>
          <Col className="mb-3">
            <Button variant="default" onClick={handleClose}>Peruuta</Button>
          </Col>
          { values?.id > -1 && (
          <Col className="mb-3 text-center">
            <Button variant="danger" onClick={handleDelete}>Poista</Button>
          </Col>
          )}
          <Col className="mb-3 text-right">
            <Button disabled={!isValid} variant="primary" onClick={handleSubmit}>Tallenna</Button>
          </Col>
        </Row>
        
      </Container>
    </>
  );
}

 const MessageTemplateFormik = withFormik({
    
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          id: 1,
        };
      }
    },
    validationSchema: props => {
      const required = "Kenttä on pakollinen";
      return Yup.object().shape({
        message: Yup.string().max(160, "Merkkiraja (160) ylitetty").required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, props }) => {
      
      if (values?.type === "chat") {
        let data = {
          ...values,
        }
        
        
        if (values?.id > -1) {
          httpUpdateMessageTemplate(values?.id, data).then(res => {
            setSubmitting(false);
            props.notify({ title:"Viestipohja", message:"Tallennettu onnistuneesti"})
            props.handleResolve();
          })
        }
        else {
          httpCreateMessageTemplate(data).then(res => {
            setSubmitting(false);
            props.notify({ title:"Viestipohja", message:"Luotu onnistuneesti"})
            props.handleResolve();
          })
        }
      }
      else if (values?.type === "calendar") {
        let data = {
          ...values,
          name: values?.message,
        }
        
        if (values?.id > -1) {
          httpUpdateCalendarMessageTemplate(values?.id, data).then(res => {
            setSubmitting(false);
            props.notify({ title:"Viestipohja", message:"Tallennettu onnistuneesti"})
            props.handleResolve();
          })
        }
        else {
          httpCreateCalendarMessageTemplate(data).then(res => {
            setSubmitting(false);
            props.notify({ title:"Viestipohja", message:"Luotu onnistuneesti"})
            props.handleResolve();
          })
        }
      }
    },
    displayName: "BasicForm"
  
  })(MessageTemplateForm)
    

const defaultValues = {
  message: "",
}

const MessageTemplate = ({messageTemplateObject, handleResolve, handleReject}) => {
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  return (
    <MessageTemplateFormik
      preSetValues={messageTemplateObject}
      handleResolve={handleResolve}
      handleReject={handleReject}
      notify={notify}
    />
  )
}
  

export default MessageTemplate;
