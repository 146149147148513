import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

// reactstrap components
import {
  CardHeader,
  CardBody,
} from "reactstrap";

// core components
import { Typography } from "stories/components/Typography"
import { Button } from "stories/components/Button"
import { Card } from "stories/components/Card"
import { Container } from "stories/layout/Container"
import { Row } from "stories/layout/Row"
import { Col } from "stories/layout/Col"

const Login = () => {

  const { loginWithRedirect, logout  } = useAuth0();
  
  const handleLoginAgain = () => {
    logout({ returnTo: window.location.origin });
  }
  
  return (
    <>
      <Container className="mt-8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7" className="">
            <Card variant="primary">
              <CardHeader className="bg-transparent pt-5 pb-1">
                
                <div className="text-center text-muted mb-2">
                  <Typography variant="h2" className="mb-2">Virhe kirjautumisessa</Typography>
                  <Typography variant="p">Palvelun käyttö vaatii kolmannen osapuolen evästeiden sallimisen</Typography>
                </div>
                
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Row className="mb-0">
                  <Col className="xs-12 text-center">
                    Salli evästeet Google Chrome selaimella
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col className="xs-12 text-center">
                    <a href="https://support.google.com/chrome/answer/95647" target="_blank">https://support.google.com/chrome/answer/95647</a>
                  </Col>
                </Row>
                
                <Row className="mb-0">
                  <Col className="xs-12 text-center">
                    Salli evästeet Safari selaimella
                  </Col>
                </Row>
                <Row className="mb-0">
                  <Col className="xs-12 text-center">
                    Muuta asetukset puhelimessa: Safari -&gt; Tietosuoja ja suojaus -&gt; "Estä seuranta sivujen välillä" pitää ottaa pois päältä 
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col className="xs-12 text-center">
                    <a href="https://support.apple.com/fi-fi/guide/safari/sfri11471/mac" target="_blank">https://support.apple.com/fi-fi/guide/safari/sfri11471/mac</a>
                  </Col>
                </Row>
                
                <div className="text-center text-muted mb-4">
                  <Button className="my-4" color="primary" type="button" onClick={handleLoginAgain}>
                    Kirjaudu uudelleen sisään
                  </Button>
                </div>
                
              </CardBody>
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
