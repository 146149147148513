import React, { useState, useCallback, useEffect } from "react";

import { Input, Checkbox, Toggle, Dropdown, Select } from "stories/forms";
import { httpGetOrganizationUnits } from "services/organizations"

const OrganizationUnitSelect = (props) => {
  
  const {
    name,
    value,
    setFieldValue,
    organizationId,
    isDisabled=false
  } = props;
  
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState()
  const [objects, setObjects] = useState()
  
  const getOrganizationUnits = () => {
    setLoading(true);
    const filters = {
      'organization_fk': organizationId,
    }
    httpGetOrganizationUnits(filters).then(res => {
      setObjects(res);
    }).finally(response => {
      setLoading(false);
    })
  }
  
  useEffect(() => {
    getOrganizationUnits()
  }, [organizationId])
  
  const getItems = () => {
    return objects && objects.map(obj => {
      return {
        label: obj.name.slice(0,32),
        value: obj.id,
      }
    })
  }
  
  const getDefaultValue = () => {
    
    const defaultValue = objects && objects.reduce((result, obj) => {
      if (obj.id === value) { result = obj }
      return result
    }, null)
    
    if (defaultValue) {
      return {
        label: defaultValue?.name, 
        value: value
      }
    }
    return null
  }
  
  if (!objects) {
    return (
      <Select
        isDisabled={isDisabled}
        key={0}
        name={name}
        label="Toimipiste" 
        isMulti={false}
        closeMenuOnSelect={true}
        options={getItems()}
        defaultValue={{
         label: "Ladataan...",
         value: 0,
        }}
        value={value}
        setFieldValue={setFieldValue} 
      />
    );
  }
  
  return (
    <Select
      isDisabled={isDisabled}
      key={1}
      name={name}
      label="Toimipiste" 
      isMulti={false}
      closeMenuOnSelect={true}
      options={getItems()}
      defaultValue={getDefaultValue()}
      value={value}
      setFieldValue={setFieldValue} 
    />
  )
}

export default OrganizationUnitSelect