import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";

// Storybook
import { Button, Card, Typography, IconButton, Modal, Link } from "stories/components";
import { Container, Row, Col } from "stories/layout";
import { Checkbox, Input } from "stories/forms";
import { TableAuto } from "stories/tables";

// Services
import { useStoreContext } from "contexts/StoreContext"
import { httpGetOrganizations } from "services/organizations"
import { useAuthContext }from "contexts/AuthContext"

// App
import OrganizationEdit from "views/pages/dashboard/organizationEdit/OrganizationEdit";

const OrganizationsList = ({ history }) => {
  const { enums } = useStoreContext();
  const { permissionLevel } = useAuthContext()
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    
  })
  
  // Table
  
  const headers = [
    { label: "Nimi"},
    { label: ""},
  ];
  
  const [rows, setRows] = useState([]);
  
  /*
  const handleCreate = () => {
    history.push(`/dashboard/organizations/edit/0/`);
  }
  
  const handleEdit = (key) => {
    history.push(`/dashboard/organizations/edit/${key}/`);
  }
  */
  
  const getTableRows = useCallback(() => {
    return rows && rows.map(row => {
      return [
        (
          <Typography>{row?.name}</Typography>
        ),
        (
          <>
            <IconButton size="xl" onClick={() => handleEditOrganization(row?.id)} iconName="settings-gear-65" />
          </>
        )
      ]
    })
  }, [rows])

  const getOrganizations = () => {
    setLoading(true);
    httpGetOrganizations(filters).then(res => {
      setRows(res);
    }).finally(response => {
      setLoading(false);
    })
  }
  
  useEffect(() => {
    getOrganizations()
  }, [])
  
  const handleFilterChange = ({ target }) => {
    const { value, name } = target;
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  
  // Modal
  
  const [selectedId, setSelectedId] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  
  const closeModal = () => {
    setSelectedId(null)
  }
  
  const handleCreateOrganization = () => {
    setSelectedId(0)
  }
  const handleEditOrganization = (id) => {
    setSelectedId(id)
  }
  const resolveModal = () => {
    setSelectedId(null)
    getOrganizations()
  }
  const rejectModal = () => {
    setSelectedId(null)
  }
  
  useEffect(() => {
    if (selectedId != null) {
      setModalOpen(true)
    }
    else {
      setModalOpen(false)
    }
  }, [selectedId])
  
  // END
  
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">

              <Row className="mb-3">
                <Col xs="6">
                  <Typography bold variant="h2" className="mb-0">Organisaatiot</Typography>
                  <Typography className="mb-0"></Typography>
                </Col>
                <Col xs="6" className="text-right">
                { permissionLevel === 3 ? (
                  <Button onClick={handleCreateOrganization}>Lisää organisaatio</Button>
                ) : null}
                </Col>
              </Row>
                
              <TableAuto
                checkboxes={false}
                headers={headers}
                rows={getTableRows()}
                loading={loading}
              />

          </div>
        </Row>
      </Container>
      
      <Modal
      /* size="modal-xl" */
        title={"Organisaatio"}
        isOpen={modalOpen}
        toggleModal={closeModal}
        closeModal={closeModal}
      >
        <OrganizationEdit
          organizationId={selectedId}
          handleResolve={resolveModal}
          handleReject={rejectModal}
          inModal={true}
        />
        
      </Modal>

      
    </>
  );
}

export default OrganizationsList;
