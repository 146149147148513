import Axios from 'axios'

import { getToken } from "./token";
import appConfig from "./config"

const authHeader = () => {
  const token = getToken()
  if (token) {
    return {
      authorization: `Bearer ${token}`
    }
  }
}
export { authHeader };

function headers() {
  let headers = {
    'content-type': 'application/json'
  }
  return { headers }
}

let axios = Axios.create({
  withCredentials: false,
  baseURL: appConfig.apiUrl,
  ...headers()
})

function handleReques(config) {
  return config
}

function handleRequesErrors(error) {
  return error
}

function handleResponse(response) {
  return response.data
}

function handleResponseErrors(error) {
 if (error && error.response)
 {
   let {data,status,statusText,config} = error.response
   let {method, url, getBaseURL, disable404 } = config
   url = url.replace(getBaseURL,'')
   let path = url.substr(-1) === '/' ? url.slice(0, -1).split('/') : url
    switch (status) {
      case 404 && !disable404:
        // history.push('/404', { data, method, url, path } )
        console.log("404");
        break
      default:
        return Promise.reject({ data, status, statusText })
    }
 }
 return Promise.reject({ data:null, status:404, statusText: "Not found" })
  
}

// Add a request interceptor
axios.interceptors.request.use(handleReques, handleRequesErrors)
// Add a response interceptor
axios.interceptors.response.use(handleResponse, handleResponseErrors)

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

export default axios