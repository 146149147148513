import React, { useEffect, useState, useCallback } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment";
import {
  ListGroupItem,
  ListGroup,
} from "reactstrap";

import { Button, Card, Typography, IconButton, Spinner } from "stories/components";
import { Container, Row, Col } from "stories/layout";
import { Input, Dropdown } from "stories/forms";

import { useAuthContext } from "contexts/AuthContext"
import { httpGetChatMessages, httpCreateChatMessage } from "services/chatMessages"
import { httpGetMessageTemplates } from "services/messageTemplates"
import { useNotification } from "stories/components/Notification"

const Chat = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
   } = props;
   
  const {
      history,
      handleResolve,
      handleReject,
   } = props;
  
  // Message templates
  const [messageTemplates, setMessageTemplates] = useState([])
  
  const getMessageTemplates = () => {
    httpGetMessageTemplates().then(res => {
      setMessageTemplates(res)
    })
  }
  
  useEffect(() => {
    getMessageTemplates()
  }, [])
  
  const handleSetMessageTemplate = (id) => {
    
    let messageTemplate = messageTemplates.reduce((result, item) => {
      if (item.id === id) { result = item }
      return result
    }, null)
    let message = messageTemplate?.message;
    
    // Tags
    // message = message.replace("{date}", eventDate.format("DD.MM.YYYY"))
    // message = message.replace("{weekday}", eventDate.format("dddd"))
    
    setFieldValue("message", message)
  }
  
  const getMessageTemplateItems = () => {
    return messageTemplates && messageTemplates.map(mt => {
      return {
        label: mt.message.slice(0,16),
        props: {
          onClick: () => handleSetMessageTemplate(mt.id),
        }
      }
    })
  }
  
  // Form
  const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    history.push("/dashboard/users");
  }
  
  return (
    <>
      <Row>
        <Col className="mb-3">
          <Input
            {...getFormikInputProps("message")}
            label="Uusi viesti"
            value={values.message}
            type="textarea"
          />
          <div style={{float:'right'}}>
            <Dropdown color="primary" size="lg" label="Lisää vakioviestipohja" items={getMessageTemplateItems()} />
          </div>
        </Col>
      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button disabled={!isValid} onClick={handleSubmit}>Lähetä viesti</Button>
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
    </>
  );
}

 const ChatFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          message: '',
        };
      }
    },
    validationSchema: props => {
      const required = "Kenttä on pakollinen";
      return Yup.object().shape({
        message: Yup.string().max(1024, "Merkkiraja ylitetty").required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, setFieldValue, resetForm, props }) => {
      const data = {
        ...values,
        senderuserid: props.userId,
      }
      httpCreateChatMessage(data).then(res => {
        setSubmitting(false);
        props.notify({ title:"Viesti", message:"Lähetetty onnistuneesti"})
        resetForm();
        props.handleResolve();
      }, error => {
        setSubmitting(false);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          })
        }
        else {
          setErrors({
            general: "Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen."
          })
        }
      })
    },
    displayName: "BasicForm"
  
  })(Chat)
    
    
const ChatView = (props) => {
  const { user, ...rest } = props;
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState();
  const { myUser } = useAuthContext();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  // API requests here
  
  const getChatMessages = () => {
    const params = {
      order_by: "-id",
      user_key: user?.key,
    };
    setLoading(true);
    httpGetChatMessages(params).then(res => {
      setMessages(res);
    }).finally(()=>{
      setLoading(false);
    })
  }
  
  useEffect(() => {
    getChatMessages()
  }, [user])
  
  
  return (
    <>
      <Row>
        <Col className="mb-5">
        { loading ? (
          <Spinner />
        ) : (
          <ListGroup data-toggle="checklist" style={{maxHeight:"300px", overflowY:"auto"}}>
            { messages && messages.map(res => (
              <ListGroupItem className="checklist-entry flex-column align-items-start py-1 px-3">
                <Typography color="text-dark" variant="small">{/*res.senderuserid*/}Asiakaspalvelu - {moment(res.timesent).format("D.M.YYYY HH:mm")}</Typography>
                <Typography>{res.message}</Typography>
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
        </Col>
      </Row>
      
      <ChatFormik handleResolve={getChatMessages} userId={user?.id} notify={notify} {...rest} />
    </>
  )
}
  

export default ChatView;
