import React, { useEffect, useState, useCallback } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"

// Stories
import { Button, Typography, Link, Modal, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer } from "stories/layout";
import { Input } from "stories/forms";
import { useNotification } from "stories/components/Notification"

// Services
import { 
  httpGetOrganizationUserRole, 
  httpUpdateOrganizationUserRole,
  httpCreateOrganizationUserInvitation,
  httpSendOrganizationUserInvitation
} from "services/organizations";

import { useAuthContext }from "contexts/AuthContext"
import { useStoreContext } from "contexts/StoreContext"
import { httpGetUser, httpCreateUser, httpUpdateUser, httpDeleteUser } from "services/companyUsers"

// App
import OrganizationSelect from "components/OrganizationSelect/OrganizationSelect"
import OrganizationUnitSelect from "components/OrganizationUnitSelect/OrganizationUnitSelect"
import UserStatusSelect from "components/UserStatusSelect/UserStatusSelect"


require('yup-phone');

function OrganizationUserEdit(props) {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     validateForm,
     setFieldValue,
   } = props;
   
   const {
      history,
      preSetValues,
      inModal,
      handleResolve,
      handleReject,
      isStaff,
      handleSendInvitation,
      myUser,
   } = props;
   
   const { enums } = useStoreContext();
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    if (inModal) {
      handleReject();
    }
    else {
      history.push("/dashboard/organizations/users");
    }
  }
  
  const handleDelete = () => {
    const result = window.confirm("Haluatko varmasti poistaa käyttäjän?");
    if (result) {
      httpDeleteUser(values?.id).then(res => {
        if (isStaff) {
          history.push("/dashboard/users");
        }
        else {
          history.push("/dashboard/myuser");
        }
      })
    }
  }
  
  const getActiveDaysLeft = (currentActiveDate) => {
    if (!currentActiveDate) {
      return "";
    }
    const now = moment().set({hour:0,minute:0,second:0,millisecond:0})
    const currentActiveDateMoment = moment(currentActiveDate);
    return currentActiveDateMoment.diff(now, 'days')
  }
  
  return (
    <>
        
        <Row>
          <Col className="mb-3">
          {values?.id ? (
            <Typography variant="h2" bold>Muokkaa organisaatio käyttäjää</Typography>
          ) : (
            <Typography variant="h2" bold>Luo organisaatio käyttäjä</Typography>
          )}
          </Col>
          {/*
          <Col className="text-right">
          { values?.user_role_id ? (
            <Button onClick={handleSendInvitation} variant="success">Lähetä kutsu</Button>
          ) : null}
          </Col>
          */}
        </Row>
        
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("first_name")}
              label="Etunimi"
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("last_name")}
              label="Sukunimi"
            />
          </Col>
        </Row>
        
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("email")}
              type="email"
              label="Sähköposti"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("phone")}
              label="Puhelin"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("activated")}
              label="Voimassa saakka"
              defaultValue={values.activatedDefaultValue}
              type="date"
              required
            />
            { values.activated && (
              <Typography variant="small">{getActiveDaysLeft(values.activated)} päivää jäljellä</Typography>
            )}
          </Col>
        </Row>
        
        <Row>
          <Col className="mb-3">
            <OrganizationSelect 
              {...getFormikInputProps("organization_fk")} 
              setFieldValue={setFieldValue} 
              isDisabled={values?.user_fk?.id == myUser?.id}
            />
          </Col>
        </Row>
        
        <Row>
          <Col className="mb-3">
            <OrganizationUnitSelect 
              {...getFormikInputProps("organization_unit_fk")} 
              setFieldValue={setFieldValue} 
              organizationId={values?.organization_fk}
              isDisabled={values?.user_fk?.id == myUser?.id}
            />
          </Col>
        </Row>
        
        <Row>
          <Col className="mb-3">
            <UserStatusSelect 
              {...getFormikInputProps("status")} 
              setFieldValue={setFieldValue}
              isDisabled={values?.user_fk?.id == myUser?.id}
            />
          </Col>
        </Row>
        
        
        <Row>
          <Col className="mb-3">
            <Button onClick={handleCancel} variant="secondary">Peruuta</Button>
          </Col>
          { values?.is_staff === false && isStaff ? (
          <Col className="mb-3 text-center">
            <Button onClick={handleDelete} variant="danger">Poista</Button>
          </Col>
          ) : null}
          <Col className="mb-3 text-right">
            <Button disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>Tallenna</Button>
            { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
          </Col>
        </Row>
        
    </>
  );
}

 const OrganizationUserEditFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          id: 0,
        };
      }
    },
    validationSchema: props => {
      const required = "Kenttä on pakollinen";
      return Yup.object().shape({
        first_name: Yup.string().nullable().required(required),
        phone: Yup.string().notRequired().test('phone', 'Puhelinnumero tulee olla muodossa esim. +358501234567', function(value) {
          if (!!value) {
            const schema = Yup.string().phone("FI", false,'Puhelinnumero tulee olla muodossa esim. +358501234567').required(required)
            return schema.isValidSync(value);
          }
          return true;
        }).nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const required = "Kenttä on pakollinen";
      
      console.log("handleSubmit")
      
      if (values?.id) {
        
        const data = {
          ...values,
          activated: values.activated ? moment(values.activated).set({hour:23,minute:59,second:59,millisecond:999}) : null,
        }
        
        httpUpdateUser(values?.id, data).then(res => {
          console.log("httpUpdateUser res", res)
          if (values?.user_role_id) {
            const data = {
              id: values?.user_role_id,
              user_fk: values?.user_id,
              organization_fk: values?.organization_fk,
              organization_unit_fk: values?.organization_unit_fk,
              status: values?.status,
            }
            httpUpdateOrganizationUserRole(values?.user_role_id, data).then(res => {
              setSubmitting(false);
              props.notify({ title:"Käyttäjä", message:"Tallennettu onnistuneesti"})
              if (props.inModal) {
                props.handleResolve();
              }
              else {
                props.history.push("/dashboard/organizations/users");
              }
            }, error => {
              setSubmitting(false);
              setErrors({
                general: error?.data?.detail,
              })
            })
          }
        }, error => {
          setSubmitting(false);
          setErrors({
            general: error?.data?.detail,
          })
        })
        
        
        /*
        httpUpdateUser(values?.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:"Käyttäjä", message:"Tallennettu onnistuneesti"})
          if (props.inModal) {
            props.handleResolve();
          }
          else {
            props.history.push("/dashboard/organizations/users");
          }
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: "Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen."
            })
          }
          
        })
        */
      }
      else {
        const data = {
          ...values,
          username: values?.email,
          password: 'fwFWE' + Math.floor(Math.random() * 100000) + 1,
          activated: values.activated ? moment(values.activated).set({hour:23,minute:59,second:59,millisecond:999}) : null,
        }
        
        httpCreateUser(data).then(res => {
          console.log("httpCreateUser res", res)
          /*
          if (res && res?.id) {
            // Create and send invitation
            const invitationData = {
              organization_fk: values?.organization_fk,
              organization_unit_fk: values?.organization_unit_fk,
              user_fk: res?.id,
              status: values?.status,
              email: values?.email,
            }
            httpCreateOrganizationUserInvitation(invitationData).then(res => {
              setSubmitting(false);
              props.notify({ title:"Käyttäjä", message:"Tallennettu ja kutsuttu"})
              if (props.inModal) {
                props.handleResolve();
              }
              else {
                props.history.push("/dashboard/organizations/users");
              }
            }, error => {
              setSubmitting(false);
              setErrors({
                general: error?.data?.detail,
              })
            })
          }
          */
        }, error => {
          setSubmitting(false);
          setErrors({
            general: error?.data?.detail,
          })
        })
      }
       
    },
    displayName: "BasicForm"
  
  })(OrganizationUserEdit)
    
    
const OrganizationUserEditView = ({match, organizationUserId, inModal, history, handleResolve, ...rest}) => {
  // API requests here
  const [loading, setLoading] = useState(false);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [preSetValues, setPreSetValues] = useState(null);
  const { myUser } = useAuthContext()
  
  const getOrganizationUser = () => {
    setLoading(true);
    httpGetOrganizationUserRole(organizationUserId).then(res => {
      setPreSetValues({
        // ...defaultValues,
        ...res,
        ...res?.user_fk,
        user_role_id: res?.id,
        user_id: res?.user_fk?.id,
        activated: res?.user_fk?.activated ? moment(res?.user_fk?.activated).format("YYYY-MM-DD") + "" : null,
        activatedDefaultValue: res?.user_fk?.activated ? moment(res?.user_fk?.activated).format("YYYY-MM-DD") + "" : null,
      });
    }).finally(()=> {
      setLoading(false);
    })
  }
      
  
  useEffect(() => {
    if (organizationUserId != null && organizationUserId > 0) {
      getOrganizationUser()
    }
  }, [organizationUserId])
  
  /*
  useEffect(() => {
    if (match && match?.params?.key) {
      setLocalKey(match?.params?.key);
    }
  }, [match])
  */
  
  const handleSendInvitation = () => {
    httpSendOrganizationUserInvitation(preSetValues?.user_role_id).then(res => {
      if (inModal) {
        handleResolve();
      }
      else {
        history.push("/dashboard/organizations/users");
      }
    })
  }
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }
  
  return (
    <OrganizationUserEditFormik 
      preSetValues={preSetValues} 
      notify={notify} 
      inModal={inModal} 
      handleSendInvitation={handleSendInvitation}
      history={history}
      handleResolve={handleResolve}
      myUser={myUser}
      {...rest} 
    />
  )
}

OrganizationUserEditView.defaultProps = {
  inModal: false,
}
  
export default OrganizationUserEditView;
