import React, { useEffect, useState, useCallback } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"

import { useStoreContext } from "contexts/StoreContext"
import { Button, Typography, Link, Modal, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer } from "stories/layout";
import { Input } from "stories/forms";
import { httpGetCustomerUser, httpUpdateCustomerUser } from "services/customerUsers";
import { useAuthContext }from "contexts/AuthContext"
import { useNotification } from "stories/components/Notification"
import CustomerInfo from "components/CustomerInfo/CustomerInfo"
import OrganizationSelect from "components/OrganizationSelect/OrganizationSelect"
import OrganizationUnitSelect from "components/OrganizationUnitSelect/OrganizationUnitSelect"
import { httpCreateOrganizationCustomer, httpUpdateOrganizationCustomer } from "services/organizations"

require('yup-phone');

function CustomerUserEdit(props) {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     validateForm,
     setFieldValue
   } = props;
   
   const {
      history,
      preSetValues,
      inModal,
      handleResolve,
      handleReject,
      isStaff,
   } = props;
   
   const { enums } = useStoreContext();
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    if (inModal) {
      handleReject();
    }
    else {
      history.push("/dashboard/customers");
    }
  }
  
  const getOptionsType = useCallback(() => {
    return enums && enums?.priima?.user?.type.map(obj => {
      return (
        <option value={obj.key}>{obj.key === "customer" ? "Seniori" : "Omainen"}</option>
      )
    })
  }, [enums])
  
  const getAdminForm = () => {
    return (
      <>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("id")}
              label="Tunniste"
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("firstname")}
              label="Etunimi"
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("lastname")}
              label="Sukunimi"
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("email")}
              type="email"
              label="Sähköposti"
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("phone")}
              label="Puhelin"
            />
          </Col>
        </Row>
        {/*
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("type")}
              label="Tyyppi"
              type="select"
              required
            >
              {getOptionsType()}
            </Input>
          </Col>
        </Row>
        */}
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("active")}
              label="Voimassa"
              defaultValue={values?.activeDefaultValue}
              type="date"
              disabled={!isStaff}
              required
            />
          </Col>
        </Row>
        
        <Row>
          <Col className="mb-3">
            <OrganizationSelect 
              {...getFormikInputProps("organization_fk")} 
              setFieldValue={setFieldValue} 
            />
          </Col>
        </Row>
        
        <Row>
          <Col className="mb-3">
            <OrganizationUnitSelect 
              {...getFormikInputProps("organization_unit_fk")} 
              setFieldValue={setFieldValue} 
              organizationId={values?.organization_fk} 
            />
              
          </Col>
        </Row>
      </>
    )
  }
  
  return (
    <>

        <Row>
          <Col className="mb-3">
            { values?.type === "customer" ? (
              <Typography variant="h2">Seniorin henkilötiedot</Typography>
            ) : (
              <Typography variant="h2">Omaisen henkilötiedot</Typography>
            )}
          </Col>
        </Row>
        
        {isStaff ? getAdminForm() : (
          <CustomerInfo customer={values} />
        )}
        
        
        
        { isStaff ? (
        <Row>
          <Col className="mb-3">
            <Button onClick={handleCancel} variant="secondary">Peruuta</Button>
          </Col>
          <Col className="mb-3 text-right">
            <Button disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>Tallenna</Button>
            { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
          </Col>
        </Row>
        ) : (
          <Row>
            <Col className="mb-3">
              <Button onClick={handleCancel} variant="secondary">Takaisin</Button>
            </Col>
          </Row>
        )}
      
    </>
  );
}

 const CustomerUserEditFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          id: 1,
        };
      }
    },
    validationSchema: props => {
      const required = "Kenttä on pakollinen";
      return Yup.object().shape({
        firstname: Yup.string().nullable(),
        lastname: Yup.string().nullable(),
        email: Yup.string().email('Tarkista sähköpostiosoitteen muoto').nullable(),
        /*
                  .phone("FI", false,'Puhelinnumero tulee olla muodossa esim. +358501234567')
          // .required(required)
          .notRequired()
          .nullable(),
          //.transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
        */
        phone: Yup.string().notRequired().test('phone', 'Puhelinnumero tulee olla muodossa esim. +358501234567', function(value) {
          if (!!value) {
            const schema = Yup.string().phone("FI", false,'Puhelinnumero tulee olla muodossa esim. +358501234567').required(required)
            return schema.isValidSync(value);
          }
          return true;
        }).nullable(),
        active: Yup.date().required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const required = "Kenttä on pakollinen";
      const data = {
        ...values,
        active: values.active ? moment(values.active) : null,
      }
      httpUpdateCustomerUser(values?.key,data).then(res => {
        setSubmitting(false);
        console.log("values?.organization_customer_id", values?.organization_customer_id)
        if (values?.organization_customer_id) {
          const oData = {
            id: values?.organization_customer_id,
            user_fk: values?.key,
            organization_fk: values?.organization_fk,
            organization_unit_fk: values?.organization_unit_fk,
          }
          httpUpdateOrganizationCustomer(values?.organization_customer_id, oData).then(res2 => {
            props.notify({ title:"Käyttäjä", message:"Tallennettu onnistuneesti"})
            if (props.inModal) {
              props.handleResolve();
            }
            else {
              props.history.push("/dashboard/customers");
            }
          })
        }
        else {
          const oData = {
            user_fk: values?.key,
            organization_fk: values?.organization_fk,
            organization_unit_fk: values?.organization_unit_fk,
          }
          httpCreateOrganizationCustomer(oData).then(res2 => {
            props.notify({ title:"Käyttäjä", message:"Tallennettu onnistuneesti"})
            if (props.inModal) {
              props.handleResolve();
            }
            else {
              props.history.push("/dashboard/customers");
            }
          })
        }
        /*
        props.notify({ title:"Käyttäjä", message:"Tallennettu onnistuneesti"})
        if (props.inModal) {
          props.handleResolve();
        }
        else {
          props.history.push("/dashboard/customers");
        }
        */
      }, error => {
        setSubmitting(false);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          })
        }
        else {
          setErrors({
            general: "Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen."
          })
        }
        
      })
       
    },
    displayName: "BasicForm"
  
  })(CustomerUserEdit)
    
    
const CustomerEditView = ({match, customerKey, inModal, ...rest}) => {
  // API requests here
  const [loading, setLoading] = useState(false);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [localKey, setLocalKey] = useState(null);
  const [preSetValues, setPreSetValues] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCustomerKey, setModalCustomerKey] = useState();
  const { myUser } = useAuthContext()
  
  const getCustomerUser = () => {
    const key = localKey;
    setLoading(true);
    httpGetCustomerUser(key, {}).then(res => {
      // Type = Customer, Get family members also
      if (res.type === "customer") {
        httpGetCustomerUser(key, {type: "customer"}).then(res => {
          setPreSetValues({
            // ...defaultValues,
            ...res,
            active:res?.active ? moment(res?.active).format("YYYY-MM-DD") + "" : null,
            activeDefaultValue:res?.active ? moment(res?.active).format("YYYY-MM-DD") + "" : null,
            organization_customer_id: res?.organization_customer ? res?.organization_customer?.id : null,
            organization_fk: res?.organization_customer?.organization_fk?.id,
            organization_unit_fk: res?.organization_customer?.organization_unit_fk?.id,
          });
        }).finally(()=> {
          setLoading(false);
        })
      }
      else {
        setPreSetValues({
          // ...defaultValues,
          ...res,
          active:res?.active ? moment(res?.active).format("YYYY-MM-DD") + "" : null,
          activeDefaultValue:res?.active ? moment(res?.active).format("YYYY-MM-DD") + "" : null,
        });
        setLoading(false);
      }
    })
  }
  
  useEffect(() => {
    if (localKey) {
      getCustomerUser()
    }
  }, [localKey])
  
  useEffect(() => {
    if (match && match?.params?.key) {
      setLocalKey(match?.params?.key);
    }
  }, [match])
  
  useEffect(() => {
    if (customerKey) {
      setLocalKey(customerKey);
    }
  }, [customerKey])
  
  const getStatus = (activatedDate) => {
    
    if (!activatedDate || moment(activatedDate).isBefore(moment())) {
      return (
        <Typography bold className="text-danger">Deaktiivinen</Typography>
      )
    }
    else {
      return (
        <Typography bold className="text-success">Voimassa {moment(activatedDate).format("D.M.YYYY")}</Typography>
      )
    }
  }
  
  const handleOpenCustomer = (key) => {
    setModalCustomerKey(key);
    setModalOpen(true);
    
  }
  
  const getFamilyMember = (fm) => {
    return (
      <>
        <Row>
          <Col className="mb-2">
            <Link onClick={() => handleOpenCustomer(fm.key)}><Typography bold>{fm.firstname} {fm.lastname} (Avaa tiedot)</Typography></Link>
          </Col>
        </Row>
        <Row>
          <Col className="mb-2">
            <Typography>{fm.email}</Typography>
          </Col>
        </Row>
        <Row>
          <Col className="mb-2">
            <Typography>{fm.phone}</Typography>
          </Col>
        </Row>
        <Row>
          <Col className="mb-5">
            <Typography>{getStatus(fm.active)}</Typography>
          </Col>
        </Row>
      </>
    )
  }
  const getFamilyMembers = () => {
    const familyMembers = preSetValues?.family_members;
    
    return familyMembers.map(fm => {
      return getFamilyMember(fm)
    })
  }
  
  const handleModalResolve = () => {
    setModalOpen(false);
    setModalCustomerKey(null);
    getCustomerUser();
  }
  const handleModalReject = () => {
    setModalOpen(false);
    setModalCustomerKey(null);
  }
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }
  
  if (preSetValues) {
    return (
      <>
        <Container fluid>
          { !inModal && (
            <Row className="mt-3 mb-5">
              <Col>
                <Typography variant="h2" bold>Asiakastiedot</Typography>
              </Col>
            </Row>
          )}
          
          <Row className="mt-3">
            <Col className="xs-12 sm-12 md-6" style={{maxWidth:"600px"}}>
              <CustomerUserEditFormik preSetValues={preSetValues} notify={notify} isStaff={myUser?.is_staff} inModal={inModal} {...rest} />
            </Col>
            { preSetValues?.type === "customer" && (
            <Col className="xs-12 sm-12 md-6">
              <Row>
                <Col className="mt-4 mb-3">
                  <Typography variant="h2">Omaiset henkilötiedot</Typography>
                </Col>
              </Row>
              {getFamilyMembers()}
            </Col>
            )}
          </Row>

          <Modal size="modal-lg" isOpen={modalOpen} toggleModal={() => setModalOpen(s => !s)} title="Asiakastiedot">
            <CustomerEditView inModal={true} customerKey={modalCustomerKey} handleResolve={handleModalResolve} handleReject={handleModalReject} />
          </Modal>
        
        </Container>
      </>
    )
  }
  return null;
}

CustomerEditView.defaultProps = {
  inModal: false,
}
  
export default CustomerEditView;
