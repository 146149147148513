import React, { useState, useCallback, useEffect } from "react";

import { Input, Checkbox, Toggle, Dropdown, Select } from "stories/forms";
import { httpGetOrganizations } from "services/organizations"

const OrganizationSelect = ({
  name,
  value,
  setFieldValue,
  label="Organisaatio",
  emptyOption=false,
  isDisabled=false
}) => {
  
  const [loading, setLoading] = useState(false)
  const [objects, setObjects] = useState()
  
  const getOrganizations = () => {
    setLoading(true);
    const filters = {}
    httpGetOrganizations(filters).then(res => {
      if (emptyOption) {
        setObjects([
          {id: undefined, name:"Kaikki"},
          ...res,
        ]);
      }
      else {
        setObjects(res)
      }
      
    }).finally(response => {
      setLoading(false);
    })
  }
  
  useEffect(() => {
    getOrganizations()
  }, [])
  
  const getItems = () => {
    return objects && objects.map(obj => {
      return {
        label: obj.name.slice(0,32),
        value: obj.id,
      }
    })
  }
  
  const getDefaultValue = () => {
    
    const defaultValue = objects && objects.reduce((result, obj) => {
      if (obj.id === value) { result = obj }
      return result
    }, null)
    
    if (defaultValue) {
      return {
        label: defaultValue?.name, 
        value: value
      }
    }
    return null
  }
  
  if (!objects) {
    return (
      <Select
        isDisabled={isDisabled}
        key={0}
        loading={true}
        name={name}
        label={label}
        isMulti={false}
        closeMenuOnSelect={true}
        options={getItems()}
        defaultValue={{
         label: "Ladataan...",
         value: 0,
        }}
        value={value}
        setFieldValue={setFieldValue}
      />
    )
  }
  
  return (
    <Select
      isDisabled={isDisabled}
      key={1}
      name={name}
      label={label}
      isMulti={false}
      closeMenuOnSelect={true}
      options={getItems()}
      defaultValue={getDefaultValue()}
      value={value}
      setFieldValue={setFieldValue} 
    />
    
  )

}

export default OrganizationSelect