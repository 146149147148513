import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";

import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

import './Dropdown.css';

export const Dropdown = ({ label, items, color, className, ...rest }) => {
  const getItems = useCallback(() => {
    return items && items.map((item, i) => {
      return (
        <DropdownItem key={i} {...item?.props}>
          { item?.label }
        </DropdownItem>
      )
    })
  }, [items])
  
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle caret color={color}  {...rest}>
        {label}
        </DropdownToggle>
        <DropdownMenu>
        {getItems()}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
};

Dropdown.propTypes = {
  
};

Dropdown.defaultProps = {
  color: "secondary",
  size: "normal",
};
