const setToken = (idToken) => {
  // Saves user token to localStorage
  localStorage.setItem('token', idToken)
}

const getToken = () => {
  // Retrieves the user token from localStorage
  const token = localStorage.getItem('token',null);
  if (token)
  {
    return token;
  }
  return null;
}

export { getToken, setToken }