import http, { authHeader } from './index'

const url = '/priima/chat_messages/'

export const httpGetChatMessages = (params) => {
  console.log("httpGetChatMessages");
  return http({
    url: `${url}`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    params,
  })
}

export const httpGetChatMessage = (key) => {
  console.log("httpGetChatMessage");
  return http({
    url: `${url}${key}/`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
  })
}

export const httpCreateChatMessage = (data) => {
  console.log("httpCreateChatMessage");
  return http({
    url: url,
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    data,
  })
}
