import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Link.css';

export const Link = ({ children, className, isExternal, ...rest }) => {
  let externalProps = {};
  if (isExternal) {
    externalProps["noreferer"] = "noreferer";
    externalProps["target"] = "_blank";
    
    return (
      <a 
        className={`${className}`}
        {...externalProps}
        {...rest}
      >
      {children}
      </a>
    )
  }
  else {
    return (
      <RouterLink 
        className={`${className}`}
        {...rest}
      >
      {children}
      </RouterLink>
    )
  }
};

Link.propTypes = {
  isExternal: PropTypes.bool,
  // External link
  href: function(props, propName, componentName) {
        if ((props['isExternal'] === true && (props[propName] == undefined || typeof(props[propName]) !== 'string'))) {
            return new Error('Please provide a href!');
        }
    },
  // Router link
  to: function(props, propName, componentName) {
        if ((props['isExternal'] === false && (props[propName] == undefined || typeof(props[propName]) !== 'string'))) {
            return new Error('Please provide a to!');
        }
    },
};


Link.defaultProps = {
  isExternal: false,
};

