import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { httpGetEnums } from "services/enums";

export const StoreContext = createContext({});

export const StoreProvider = ({ children }) => {
  const [enums, setEnums] = useState();
  
  const getEnums = () => {
    httpGetEnums().then(response => {
      setEnums(response);
    }, error => {
      console.log("getEnums error", error);
    })
  }
  
  useEffect(() => {
    getEnums();
    handleResize()
  }, []);
  
  // Mobile detect BEGIN
  
  const [isMobile, setIsMobile] = useState(false)
  
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })
  
  // Mobile detect END
  
  return (
      <StoreContext.Provider
        value={{
          enums,
          isMobile,
          setIsMobile,
        }}
      >
        {children}
      </StoreContext.Provider>
    );
  
};

StoreContext.defaultProps = {};
StoreContext.propTypes = {
  children: PropTypes.node.isRequired
};

export const useStoreContext = () =>
  useContext(StoreContext);
