import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";

import './Checkbox.css';

export const Checkbox = ({ className, ...rest }) => {
  return (
    <div className="custom-control custom-checkbox">
      <input
        className={`custom-control-input ${className ? className : ""}`}
        id="table-check-all"
        type="checkbox"
        {...rest}
      />
      <label
        className="custom-control-label"
        htmlFor="table-check-all"
      />
    </div>
  )
};

Checkbox.propTypes = {
  
};

Checkbox.defaultProps = {
  
};
