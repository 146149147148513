import React from 'react';
import PropTypes from 'prop-types';
import { Button as BootstrapButton } from "reactstrap";
import './IconButton.css';

/**
 * Primary UI component for user interaction
 */
export const IconButton = ({ iconName, size, className, color, children, ...rest }) => {
  
  return (
    <BootstrapButton
      className={`btn-icon icon_button_${size} ${className ? className : ""}`}
      type="button"
      color={color}
      size={size}
      {...rest}
    >
      <span className="btn-inner--icon">
        <i 
          className={`ni ni-${iconName}`}
        />
      </span>
    </BootstrapButton>
  );
};

IconButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['primary','secondary','default','info','success','danger','warning']),
  size: PropTypes.oneOf(['sm', 'normal', 'lg','xl']),
  onClick: PropTypes.func,
};

IconButton.defaultProps = {
  color: 'default',
  size: 'normal',
  iconName: 'app',
};
