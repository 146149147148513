import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { useAuthContext } from "contexts/AuthContext"

import DashboardLayout from "layouts/Dashboard.js";
import AuthLayout from "layouts/Auth.js";

const App = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { myUser, permissionLevel } = useAuthContext();
  
  if (isLoading) {
    return (
      <div>Ladataan...</div>
    )
  }
  
  return (
    <>
      { !isAuthenticated && !myUser ? (
        <Switch>
          <Route path="/auth/cookies" render={(props) => <AuthLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="*" to="/auth/login" />
        </Switch>
      ) : (
        <Switch>
          <Route path="/auth/cookies" render={(props) => <AuthLayout {...props} />} />
          <Route path="/dashboard" render={(props) => <DashboardLayout {...props} />} />
          { permissionLevel === 1 ? (
            <Redirect from="*" to="/dashboard/customers" />
          ) : (
            <Redirect from="*" to="/dashboard/frontpage" />
          )}
          
        </Switch>
      )}
      
    </>
  )
}

export default App;