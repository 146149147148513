import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";

import {
  FormGroup,
  Input as BootstrapInput,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

import { Typography } from "stories/components"

import './Input.css';

export const Input = ({ type, className, label, focused, errorText, required, children, ...rest }) => {
  
  if (type === 'email') {
    return (
      <FormGroup
        className={classnames("mb-3", {
          focused: focused,
        })}
      >
        {label && (
          <Typography bold>{label} {required ? "*" : ""}</Typography>
        )}
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83" />
            </InputGroupText>
          </InputGroupAddon>
          <BootstrapInput
            placeholder="Email"
            type="email"
            {...rest}
          />
        </InputGroup>
        { errorText && (
        <Typography className="text-danger">{errorText}</Typography>
        )}
      </FormGroup>
    )
  }
  else if (type === 'password') {
    return (
      <FormGroup className={classnames({
        focused: focused,
      })}>
        {label && (
          <Typography bold>{label} {required ? "*" : ""}</Typography>
        )}
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-lock-circle-open" />
            </InputGroupText>
          </InputGroupAddon>
          <BootstrapInput
            placeholder="Password"
            type="password"
            {...rest}
          />
        </InputGroup>
        { errorText && (
        <Typography className="text-danger">{errorText}</Typography>
        )}
      </FormGroup>
    )
  }
  else if (type === 'select') {
    return (
      <FormGroup 
        className={`${focused ? focused : ""} mb-2`}
      >
        {label && (
          <Typography bold>{label} {required ? "*" : ""}</Typography>
        )}
          <BootstrapInput
            label=""
            type="select"
            {...rest}
          >
          {children}
          </BootstrapInput>
        { errorText && (
        <Typography className="text-danger">{errorText}</Typography>
        )}
      </FormGroup>
    )
  }
  else {
    return (
      <>
      {label && (
        <Typography bold>{label} {required ? "*" : ""}</Typography>
      )}
      <BootstrapInput
        className={`${className}`}
        type={type ? type : "text"}
        {...rest}
      />
      { errorText && (
        <Typography className="text-danger">{errorText}</Typography>
      )}
      </>
    )
  }
  
  
};

Input.propTypes = {
  focused: PropTypes.bool,
  type: PropTypes.oneOf(['text','email','password','number','date']),
  // backgroundColor: PropTypes.string,
  // size: PropTypes.oneOf(['sm', 'normal', 'lg']),
  label: PropTypes.string,
  errorText: PropTypes.string,
  // onClick: PropTypes.func,
  required: PropTypes.bool,
};

Input.defaultProps = {
  focused: false,
  required: false,
  type: 'text',
};
