import React, { useEffect, useState, useCallback } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"

// Stories
import { Button, Typography, Link, Modal, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer } from "stories/layout";
import { Input } from "stories/forms";
import { useNotification } from "stories/components/Notification"

// Services
import { httpGetOrganizationUnit, httpCreateOrganizationUnit, httpUpdateOrganizationUnit, httpDeleteOrganizationUnit } from "services/organizations";
import { useAuthContext }from "contexts/AuthContext"
import { useStoreContext } from "contexts/StoreContext"

import OrganizationSelect from "components/OrganizationSelect/OrganizationSelect"

require('yup-phone');

function OrganizationUnitEdit(props) {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     validateForm,
     setFieldValue
   } = props;
   
   const {
      history,
      preSetValues,
      inModal,
      handleResolve,
      handleReject,
      isStaff,
      permissionLevel,
   } = props;
   
   const { enums } = useStoreContext();
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    if (inModal) {
      handleReject();
    }
    else {
      history.push("/dashboard/organizations/list");
    }
  }
  
  const handleDelete = () => {
    
  }
  
  return (
    <>
        <Row>
          <Col className="mb-3">
            <Typography variant="h2">Toimipisteen tiedot</Typography>
          </Col>
        </Row>
        
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("name")}
              label="Nimi"
            />
          </Col>
        </Row>
        
        { permissionLevel === 3 ? (
        <Row>
          <Col className="mb-3">
            <OrganizationSelect 
              {...getFormikInputProps("organization_fk")} 
              setFieldValue={setFieldValue} 
            />
          </Col>
        </Row>
        ) : null }
        
        <Row>
          <Col className="mb-3">
            <Button onClick={handleCancel} variant="secondary">Peruuta</Button>
          </Col>
          {/*
          <Col className="mb-3 text-center">
            { values?.id && values?.id > 0 ? (
              <Button onClick={handleDelete} variant="danger">Poista</Button>
            ) : null}
          </Col>
          */}
          <Col className="mb-3 text-right">
            <Button variant="success" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>Tallenna</Button>
            { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
          </Col>
        </Row>
    </>
  );
}

 const OrganizationUnitEditFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          id: 0,
        };
      }
    },
    validationSchema: props => {
      const required = "Kenttä on pakollinen";
      return Yup.object().shape({
        name: Yup.string().nullable().required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {
        handleResolve,
        history,
        inModal,
        notify,
        permissionLevel,
        userOrganizationId,
      } = props;
      
      const required = "Kenttä on pakollinen";
      let data = {
        ...values,
      }
      
      if (permissionLevel < 3) {
        data["organization_fk"] = userOrganizationId;
      }
      
      if (values?.id) {
        httpUpdateOrganizationUnit(values?.id, data).then(res => {
          setSubmitting(false);
          notify({ title:"Toimipiste", message:"Tallennettu onnistuneesti"})
          if (inModal) {
            handleResolve();
          }
          else {
            history.push("/dashboard/organizations/units");
          }
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: "Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen."
            })
          }
          
        })
      }
      else {
        httpCreateOrganizationUnit(data).then(res => {
          setSubmitting(false);
          notify({ title:"Toimipiste", message:"Luotu onnistuneesti"})
          if (inModal) {
            handleResolve();
          }
          else {
            history.push("/dashboard/organizations/units");
          }
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: "Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen."
            })
          }
          
        })
        
      }
       
    },
    displayName: "BasicForm"
  
  })(OrganizationUnitEdit)
    
    
const OrganizationUnitEditView = ({match, organizationUnitId, inModal, ...rest}) => {
  // API requests here
  const [loading, setLoading] = useState(false);
  const { permissionLevel, userOrganizationId } = useAuthContext()
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [preSetValues, setPreSetValues] = useState(null);
  
  const getOrganizationUnit = () => {
    setLoading(true);
    httpGetOrganizationUnit(organizationUnitId).then(res => {
      setPreSetValues({
        // ...defaultValues,
        ...res,
      });
    }).finally(()=> {
      setLoading(false);
    })
  }
      
  
  useEffect(() => {
    if (organizationUnitId != null && organizationUnitId > 0) {
      getOrganizationUnit()
    }
  }, [organizationUnitId])
  
  /*
  useEffect(() => {
    if (match && match?.params?.key) {
      setLocalKey(match?.params?.key);
    }
  }, [match])
  */
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }
  
  return (
    <OrganizationUnitEditFormik 
      preSetValues={preSetValues} 
      notify={notify} 
      inModal={inModal} 
      permissionLevel={permissionLevel}
      userOrganizationId={userOrganizationId}
      {...rest} 
    />
  )
}

OrganizationUnitEditView.defaultProps = {
  inModal: false,
}
  
export default OrganizationUnitEditView;
