import React, { useEffect, useState, useCallback } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"

// Stories
import { Button, Typography, Link, Modal, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer } from "stories/layout";
import { Input } from "stories/forms";
import { useNotification } from "stories/components/Notification"

// Services
import { httpGetOrganization, httpCreateOrganization, httpCreateOrganizationAndInvitation, httpUpdateOrganization } from "services/organizations";
import { useAuthContext }from "contexts/AuthContext"
import { useStoreContext } from "contexts/StoreContext"

require('yup-phone');

function OrganizationEdit(props) {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     validateForm
   } = props;
   
   const {
      history,
      preSetValues,
      inModal,
      handleResolve,
      handleReject,
      isStaff,
   } = props;
   
   const { enums } = useStoreContext();
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    if (inModal) {
      handleReject();
    }
    else {
      history.push("/dashboard/organizations/list");
    }
  }
  
  const handleDelete = () => {
    
  }
  
  return (
    <>
        <Row>
          <Col className="mb-3">
            <Typography variant="h2">Organisaation tiedot</Typography>
          </Col>
        </Row>
        
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("name")}
              label="Nimi"
            />
          </Col>
        </Row>
        
        { values?.id === 0 ? (
        <>
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("phone")}
                label="Puhelin"
                required
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("email")}
                label="Pääkäyttäjän sähköposti"
                helpText="(kutsu lähetetään tänne)"
                type="email"
                required
              />
            </Col>
          </Row>
        </>
        ) : null }
        
        <Row>
          <Col className="mb-3">
            <Button onClick={handleCancel} variant="secondary">Peruuta</Button>
          </Col>
          {/*
          <Col className="mb-3 text-center">
            { values?.id && values?.id > 0 ? (
              <Button onClick={handleDelete} variant="danger">Poista</Button>
            ) : null}
          </Col>
          */}
          <Col className="mb-3 text-right">
            <Button variant="success" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>Tallenna</Button>
            { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
          </Col>
        </Row>
    </>
  );
}

 const OrganizationEditFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          id: 0,
        };
      }
    },
    validationSchema: props => {
      const required = "Kenttä on pakollinen";
      return Yup.object().shape({
        name: Yup.string().nullable().required(required),
        phone: Yup.string().notRequired().test('phone', 'Puhelinnumero tulee olla muodossa esim. +358501234567', function(value) {
          if (!!value) {
            const schema = Yup.string().phone("FI", false,'Puhelinnumero tulee olla muodossa esim. +358501234567').required(required)
            return schema.isValidSync(value);
          }
          return true;
        }).nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const required = "Kenttä on pakollinen";
      const data = {
        ...values,
      }
      
      if (values?.id) {
        httpUpdateOrganization(values?.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:"Organisaatio", message:"Tallennettu onnistuneesti"})
          if (props.inModal) {
            props.handleResolve();
          }
          else {
            props.history.push("/dashboard/organizations/list");
          }
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: "Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen."
            })
          }
          
        })
      }
      else {
        httpCreateOrganizationAndInvitation(data).then(res => {
          setSubmitting(false);
          props.notify({ title:"Organisaatio", message:"Luotu onnistuneesti"})
          if (props.inModal) {
            props.handleResolve();
          }
          else {
            props.history.push("/dashboard/organizations/list");
          }
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: "Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen."
            })
          }
          
        })
        
      }
       
    },
    displayName: "BasicForm"
  
  })(OrganizationEdit)
    
    
const OrganizationEditView = ({match, organizationId, inModal, ...rest}) => {
  // API requests here
  const [loading, setLoading] = useState(false);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [preSetValues, setPreSetValues] = useState(null);
  
  const getOrganization = () => {
    setLoading(true);
    httpGetOrganization(organizationId).then(res => {
      setPreSetValues({
        // ...defaultValues,
        ...res,
      });
    }).finally(()=> {
      setLoading(false);
    })
  }
      
  
  useEffect(() => {
    if (organizationId != null && organizationId > 0) {
      getOrganization()
    }
  }, [organizationId])
  
  /*
  useEffect(() => {
    if (match && match?.params?.key) {
      setLocalKey(match?.params?.key);
    }
  }, [match])
  */
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }
  
  return (
    <OrganizationEditFormik 
      preSetValues={preSetValues} 
      notify={notify} 
      inModal={inModal} 
      {...rest} 
    />
  )
}

OrganizationEditView.defaultProps = {
  inModal: false,
}
  
export default OrganizationEditView;
