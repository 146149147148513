import React, {useEffect, useState} from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import { useAuthContext }from "contexts/AuthContext"
import { useStoreContext } from "contexts/StoreContext"

import routesDashboard from "routes_dashboard.js";
// import routesAdmin from "routes_admin.js";
// import routesOrganization from "routes_dashboard.js";
// import routesOrganizationLocation from "routes_dashboard.js";

import { Container, Row, Col } from "stories/layout";

function Dashboard({history, sidenavAlwaysOpen}) {
  const { myUser, permissionLevel } = useAuthContext()
  const { isMobile } = useStoreContext()
  const [activeRoutes, setActiveRoutes] = useState();
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  
  useEffect(() => {
    setActiveRoutes(routesDashboard)
  }, [myUser])
  
  useEffect(() => {
    if (isMobile === true) {
      setSidenavOpen(false)
    }
    /*
    if (permissionLevel && permissionLevel === 1) {
      
    }
    */
  }, [isMobile])
  
  // Mobile detect END
  
  useEffect(() => {
    if (permissionLevel && permissionLevel === 1) {
      history.push("/dashboard/customers");
    }
  }, [permissionLevel])
  
  const getRoutes = (_routes) => {
    // Returns only right routes, by user permissions and layout
    return _routes && _routes.filter((route, key) => {
      if (route?.layout === "/dashboard") {
        if (route?.permissions) {
          if (route?.permissions.includes(permissionLevel)) {
            return true;
          }
        }
        else {
          return true;
        }
      }
      return false;
    })
  }
  
  const listRoutes = (_routes) => {
    // Construct Route components
    const filteredRoutes = getRoutes(_routes);
    
    return filteredRoutes && filteredRoutes.map((route, key) => {
      if (route?.collapse) {
        return listRoutes(route?.views);
      }
      if (route?.layout === "/dashboard") {
        if (route?.permissions) {
          if (route?.permissions.includes(permissionLevel)) {
            return (
              <Route
                exact
                path={route?.layout + route?.path}
                component={route?.component}
                key={key}
              />
            );
          }
        }
        else {
          return (
            <Route
              exact
              path={route?.layout + route?.path}
              component={route?.component}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    if (!activeRoutes) {
      return ""
    }
    for (let i = 0; i < activeRoutes.length; i++) {
      if (location.pathname.indexOf(activeRoutes[i].layout + activeRoutes[i].path) !== -1) {
        return activeRoutes[i].name;
      }
    }
    return "";
  };
  // toggles collapse between mini sidenav and normal
  /*
  const toggleSidenav = (e) => {
    if (sidenavAlwaysOpen) {
      return;
    }
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  */
  
  const toggleSidenav = () => {
    setSidenavOpen(s => !s)
  }
  
  
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "light"
      : "dark";
  };

  return (
    <>
      { sidenavOpen ? (
        <Sidebar
          routes={getRoutes(activeRoutes)}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          sidenavAlwaysOpen={sidenavAlwaysOpen}
          logo={{
            innerLink: "/dashboard/frontpage",
            imgSrc: require("assets/images/priima_logo.png").default,
            imgAlt: "...",
          }}
          rtlActive={false}
        />
      ) : null }
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          theme={getNavbarTheme()}
          sideNavToggleButtonVisible={false}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        <div className="mt-3">
          <Switch>
            {listRoutes(activeRoutes)}
          </Switch>
        </div>
        <AdminFooter />
      </div>
      {/*
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
      */}
    </>
  );
}

Dashboard.defaultProps = {
  sidenavAlwaysOpen: true,
}

export default Dashboard;
