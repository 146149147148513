import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";
import { useAuthContext }from "contexts/AuthContext"

function AdminNavbar({ theme, sidenavOpen, toggleSidenav, sideNavToggleButtonVisible }) {
  
  const { user } = useAuth0();
  const { myUser } = useAuthContext()
  
  const getUserName = () => {
    if (myUser && myUser?.first_name && myUser?.last_name) {
      return `${myUser?.first_name} ${myUser?.last_name}`
    }
    else {
      return user?.nickname
    }
  }
  
  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-info": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            
            <Nav className="align-items-center ml-xs-auto" navbar>
              <NavItem>
                <div>
                  <button onClick={toggleSidenav}>
                    <i className="fas fa-bars"></i>
                  </button>
                </div>
              </NavItem>
            </Nav>
            
            <Nav className="align-items-center ml-auto" navbar>
              <UncontrolledDropdown nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={user.picture}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {getUserName()}
                    </span>
                  </Media>
                </Media>
              </UncontrolledDropdown>
            </Nav>
            
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
