import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";

// Storybook
import { Button, Card, Typography, IconButton, Modal, Link } from "stories/components";
import { Container, Row, Col } from "stories/layout";
import { Checkbox, Input } from "stories/forms";
import { TableAuto } from "stories/tables";

// Services
import { useStoreContext } from "contexts/StoreContext"
import { useAuthContext }from "contexts/AuthContext"
import { httpGetOrganizationUserRoles } from "services/organizations"

// App
import OrganizationUserEdit from "views/pages/dashboard/organizationUserEdit/OrganizationUserEdit";
import OrganizationUserInvite from "views/pages/dashboard/organizationUserInvite/OrganizationUserInvite";
import OrganizationSelect from "components/OrganizationSelect/OrganizationSelect"

const OrganizationUsersList = ({ history }) => {
  const { enums } = useStoreContext();
  const { permissionLevel } = useAuthContext()
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    
  })
  
  // Table
  
  const headers = [
    { label: ""},
    { label: "Käyttäjä"},
    { label: "Sähköposti"},
    { label: "Puhelinnumero"},
    { label: "Toimipiste"},
    { label: "Rooli"},
    { label: "Tila"},
    { label: ""},
  ];
  
  const [rows, setRows] = useState([]);
  
  const handleEdit = (key) => {
    history.push(`/dashboard/customers/edit/${key}/`);
  }
  
  const getActive = (activated) => {
    const activatedMoment = moment(activated)
    if (moment().isBefore(activatedMoment)) {
      return (
        <Typography className="text-success" bold>Aktiivinen {activatedMoment.format("D.M.YYYY")} saakka</Typography>
      )
    }
    else {
      return (
        <Typography className="text-danger" bold>Ei aktiivinen</Typography>
      )
    }
  }
  
  const getRoleName = (role, isStaff) => {
    if (isStaff) {
      return "SuperAdmin"
    }
    else if (role === 100) {
      return "Pääkäyttäjä"
    }
    else {
      return "Peruskäyttäjä"
    }
  }
  
  
  
  const getTableRows = useCallback(() => {
    return rows && rows.map(row => {
      return [
        (
          <Typography>#{row?.user_fk?.id}</Typography>
        ),
        (
          <Typography>{row?.user_fk?.first_name} {row?.user_fk?.last_name}</Typography>
        ),
        (
          <Typography>{row?.user_fk?.email}</Typography>
        ),
        (
          <Typography>{row?.user_fk?.phone}</Typography>
        ),
        (
          <Typography bold>{row?.organization_fk?.name} / {row?.organization_unit_fk ? row?.organization_unit_fk?.name : "-"}</Typography>
        ),
        (
          <Typography>{getRoleName(row?.status, row?.user_fk?.is_staff)}</Typography>
        ),
        (
          <>{getActive(row?.user_fk?.activated)}</>
        ),
        (
          <>
            <IconButton size="xl" onClick={() => handleEditOrganizationUser(row?.id)} iconName="settings-gear-65" />
          </>
        ),
      ]
    })
  }, [rows])

  const getOrganizations = () => {
    setLoading(true);
    httpGetOrganizationUserRoles(filters).then(res => {
      setRows(res);
    }).finally(response => {
      setLoading(false);
    })
  }
  
  useEffect(() => {
    getOrganizations()
  }, [])
  
  const setFieldValue = (name, value) => {
    setFilters(s => ({
      ...s,
      [name]: value,
    }))
  }
  
  const handleFilterChange = ({ target }) => {
    const { value, name } = target;
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  
  // Modal New user invite
  
  const [modalNewOpen, setModalNewOpen] = useState(false)
  
  const closeNewModal = () => {
    setModalNewOpen(false)
  }
  
  const handleCreateOrganizationUser = () => {
    setModalNewOpen(true)
  }
  const resolveNewModal = () => {
    setModalNewOpen(false)
    getOrganizations()
  }
  const rejectNewModal = () => {
    setModalNewOpen(false)
  }
  
  // END
  
  // Modal Edit
  
  const [selectedId, setSelectedId] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  
  const closeModal = () => {
    setSelectedId(null)
  }
  
  const handleEditOrganizationUser = (id) => {
    setSelectedId(id)
  }
  const resolveModal = () => {
    setSelectedId(null)
    getOrganizations()
  }
  const rejectModal = () => {
    setSelectedId(null)
  }
  
  useEffect(() => {
    if (selectedId != null) {
      setModalOpen(true)
    }
    else {
      setModalOpen(false)
    }
  }, [selectedId])
  
  // END
  
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">

              <Row className="mb-3">
                <Col xs="6">
                  <Typography bold variant="h2" className="mb-0">Organisaatiokäyttäjät</Typography>
                  <Typography className="mb-0">Voit hallita täältä palvelutyöntekijöitä</Typography>
                </Col>
                <Col xs="6" className="text-right">
                  <Button onClick={handleCreateOrganizationUser}>Lisää organisaatiokäyttäjä</Button>
                </Col>
              </Row>
              
              { permissionLevel === 3 ? (
              <form onSubmit={getOrganizations}>
                <Row>
                  <Col>
                    <OrganizationSelect
                      name="organization_fk"
                      value={filters?.organization_fk}
                      setFieldValue={setFieldValue} 
                      label={null}
                      emptyOption={true}
                    />
                  </Col>
                  <Col><Button onClick={getOrganizations}>Hae</Button></Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
              </form>
              ) : null }
                
              <TableAuto
                checkboxes={false}
                headers={headers}
                rows={getTableRows()}
                loading={loading}
              />

          </div>
        </Row>
        
        <Modal
        /* size="modal-xl" */
          title={"Uusi organisaatiokäyttäjä"}
          isOpen={modalNewOpen}
          toggleModal={closeNewModal}
          closeModal={closeNewModal}
        >
          <OrganizationUserInvite
            handleResolve={resolveNewModal}
            handleReject={rejectNewModal}
            inModal={true}
          />
          
        </Modal>
        
        <Modal
        /* size="modal-xl" */
          title={"Organisaatiokäyttäjä"}
          isOpen={modalOpen}
          toggleModal={closeModal}
          closeModal={closeModal}
        >
          <OrganizationUserEdit
            organizationUserId={selectedId}
            handleResolve={resolveModal}
            handleReject={rejectModal}
            inModal={true}
          />
          
        </Modal>
      
      </Container>
      

      
    </>
  );
}

export default OrganizationUsersList;
