import React from 'react';
import PropTypes from 'prop-types';
import './typography.css';

export const Typography = ({ variant, bold, children, className, color, ...rest }) => {
  
  if (variant === 'h1') {
    return (
      <h1
        className={`mb-0 ${color ? color : ""} ${className ? className : ""} ${bold ? "header-bold" : ""}`}
        {...rest}
      >
      {children}
      </h1>
    )
  }
  else if (variant === 'h2') {
    return (
      <h2
        className={`mb-0 ${color ? color : ""} ${className ? className : ""} ${bold ? "header-bold" : ""}`}
        {...rest}
      >
      {children}
      </h2>
    )
  }
  else if (variant === 'h3') {
    return (
      <h3
        className={`mb-0 ${color ? color : ""} ${className ? className : ""} ${bold ? "header-bold" : ""}`}
        {...rest}
      >
      {children}
      </h3>
    )
  }
  else if (variant === 'h4') {
    return (
      <h4
        className={`mb-0 ${color ? color : ""} ${className ? className : ""} ${bold ? "header-bold" : ""}`}
        {...rest}
      >
      {children}
      </h4>
    )
  }
  else if (variant === 'h5') {
    return (
      <h5
        className={`mb-0 ${color ? color : ""} ${className ? className : ""} ${bold ? "header-bold" : ""}`}
        {...rest}
      >
      {children}
      </h5>
    )
  }
  else if (variant === 'h6') {
    return (
      <h6
        className={`mb-0 ${color ? color : ""} ${className ? className : ""} ${bold ? "header-bold" : ""}`}
        {...rest}
      >
      {children}
      </h6>
    )
  }
  else if (variant === 'display1') {
    return (
      <h1
        className={`display-1 mb-0 ${color ? color : ""} ${className ? className : ""} ${bold ? "text-bold" : ""}`}
        {...rest}
      >
      {children}
      </h1>
    )
  }
  else if (variant === 'display2') {
    return (
      <h2
        className={`display-2 mb-0 ${color ? color : ""} ${className ? className : ""} ${bold ? "text-bold" : ""}`}
        {...rest}
      >
      {children}
      </h2>
    )
  }
  else if (variant === 'display3') {
    return (
      <h3
        className={`display-3 mb-0 ${color ? color : ""} ${className ? className : ""} ${bold ? "text-bold" : ""}`}
        {...rest}
      >
      {children}
      </h3>
    )
  }
  else if (variant === 'display4') {
    return (
      <h4
        className={`display-4 mb-0 ${color ? color : ""} ${className ? className : ""} ${bold ? "text-bold" : ""}`}
        {...rest}
      >
      {children}
      </h4>
    )
  }
  else if (variant === 'display5') {
    return (
      <h5
        className={`display-5 mb-0 ${color ? color : ""} ${className ? className : ""} ${bold ? "text-bold" : ""}`}
        {...rest}
      >
      {children}
      </h5>
    )
  }
  else if (variant === 'display6') {
    return (
      <h6
        className={`display-6 mb-0 ${color ? color : ""} ${className ? className : ""} ${bold ? "text-bold" : ""}`}
        {...rest}
      >
      {children}
      </h6>
    )
  }
  else if (variant === 'lead') {
    return (
      <p
        className={`lead ${color ? color : ""} ${className ? className : ""} ${bold ? "text-bold" : ""}`}
        {...rest}
      >
      {children}
      </p>
    )
  }
  else if (variant === 'small') {
    return (
      <p
        className={`small mb-0 ${color ? color : ""} ${className ? className : ""} ${bold ? "text-bold" : ""}`}
        {...rest}
      >
      {children}
      </p>
    )
  }
  return (
    <p 
      className={`mb-0 ${color ? color : ""} ${className ? className : ""} ${bold ? "text-bold" : ""}`}
      {...rest}
    >
    {children}
    </p>
  )
};

Typography.propTypes = {
  bold: PropTypes.bool,
  variant: PropTypes.oneOf(['h1','h2','h3','h4','h5','h6','display1','display2','display3','display4','display5','display6','p','lead']),
  color: PropTypes.oneOf(['text-muted','text-primary','text-info','text-success','text-warning','text-danger','text-dark']),
  // backgroundColor: PropTypes.string,
  // size: PropTypes.oneOf(['sm', 'normal', 'lg']),
  // label: PropTypes.string.isRequired,
  // onClick: PropTypes.func,
};

Typography.defaultProps = {
  variant: 'p',
  bold: false,
};
