import React, { useCallback, useState } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import ReactBSAlert from "react-bootstrap-sweetalert";

import { Button, Typography } from "stories/components";
import { Row, Col, FormContainer } from "stories/layout";
import { Input } from "stories/forms";
import { httpCreateUser } from "services/companyUsers"
import { useNotification } from "stories/components/Notification"

import { httpCreateOrganizationUserRole } from "services/organizations"
import OrganizationSelect from "components/OrganizationSelect/OrganizationSelect"
import OrganizationUnitSelect from "components/OrganizationUnitSelect/OrganizationUnitSelect"

require('yup-phone');

function CompanyUserAdd(props) {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     validateForm,
     setFieldValue
   } = props;
   
   const {
      history,
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    history.push("/dashboard/users");
  }
  
  const getActiveDaysLeft = (currentActiveDate) => {
    if (!currentActiveDate) {
      return "";
    }
    const now = moment().set({hour:0,minute:0,second:0,millisecond:0})
    const currentActiveDateMoment = moment(currentActiveDate);
    return currentActiveDateMoment.diff(now, 'days')
  }
  
  return (
    <>
      <FormContainer>
        
        <Row>
          <Col className="mb-3">
            <Typography variant="h2" bold>Uusi käyttäjä</Typography>
          </Col>
        </Row>
        
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("first_name")}
              label="Etunimi"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("last_name")}
              label="Sukunimi"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("email")}
              type="email"
              label="Sähköposti"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("phone")}
              label="Puhelin"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("activated")}
              label="Voimassa saakka"
              defaultValue={values.activatedDefaultValue}
              type="date"
              required
            />
            { values.activated && (
              <Typography variant="small">{getActiveDaysLeft(values.activated)} päivää jäljellä</Typography>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <OrganizationSelect 
              {...getFormikInputProps("organization_fk")} 
              setFieldValue={setFieldValue} 
            />
          </Col>
        </Row>
        
        <Row>
          <Col className="mb-3">
            <OrganizationUnitSelect 
              {...getFormikInputProps("organization_unit_fk")} 
              setFieldValue={setFieldValue} 
              organizationId={values?.organization_fk} 
            />
              
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Button onClick={handleCancel} variant="secondary">Peruuta</Button>
          </Col>
          <Col className="mb-3 text-right">
            <Button disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>Tallenna</Button>
            { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
          </Col>
        </Row>
      </FormContainer>
    </>
  );
}

const defaultValues = {
  id: -1,
  first_name: '',
  last_name: '',
  email: '',
  phone: '+358',
  activated: moment().set({hour:23,minute:59,second:59,millisecond:999}).format("YYYY-MM-DD"),
}

 const CompanyUserAddFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues
      }
    },
    validationSchema: props => {
      const required = "Kenttä on pakollinen";
      
      return Yup.object().shape({
        first_name: Yup.string().required(required),
        last_name: Yup.string().required(required),
        email: Yup.string().email('Tarkista sähköpostiosoitteen muoto').required(required),
        phone: Yup.string().phone("FI", false,'Puhelinnumero tulee olla muodossa esim. +358501234567').required(required).nullable(),
        activated: Yup.date().required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const data = {
        ...values,
        username: values?.email,
        password: 'fwFWE' + Math.floor(Math.random() * 100000) + 1,
        activated: values.activated ? moment(values.activated).set({hour:23,minute:59,second:59,millisecond:999}) : null,
      }
      httpCreateUser(data).then(res => {
        setSubmitting(false);
        props.handleResolve()
      }, error => {
        setSubmitting(false);
        console.log("httpCreateUser:error", error);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          })
        }
        else if (error?.data?.username) {
          setErrors({
            general: error?.data?.username[0],
          })
        }
        else {
          setErrors({
            general: "Tuntematon virhe. Ota yhteys ylläpitoon.",
          })
        }

      })
    },
    displayName: "BasicForm"
  
  })(CompanyUserAdd)
    
    
const CompanyUserAddView = ({match, history}) => {
  // API requests here
  
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [infoAlert, setInfoAlert] = useState();
  
  const handleModalConfirm = () => {
    setInfoAlert(null);
    history.push("/dashboard/users");
  }
  
  const handleResolve = () => {
    notify({ title:"Käyttäjä", message:"Kutsu lähetetty onnistuneesti"})
    handleModalConfirm();
    /*
    setInfoAlert(
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Lähetetty"
        onConfirm={() => handleModalConfirm()}
        onCancel={() => handleModalConfirm()}
        btnSize=""
      >
        Kutsu lähetetty onnistuneesti
      </ReactBSAlert>
    )
    */
  }
  
  return (
    <>
      <CompanyUserAddFormik history={history} handleResolve={handleResolve} />
      {infoAlert}
    </>
  )
}
  

export default CompanyUserAddView;
