import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from 'moment'

import { Button, Card, Typography, IconButton } from "stories/components";
import { Container, Row, Col } from "stories/layout";
import { Input, Checkbox, Toggle, Dropdown } from "stories/forms";

import { httpGetCalendarEvent, httpCreateCalendarEvent, httpUpdateCalendarEvent, httpDeleteCalendarEvent } from "services/calendarEvents";
import { httpGetCalendarMessageTemplates } from "services/messageTemplatesCalendar"
import { useAuthContext }from "contexts/AuthContext"
import { useNotification } from "stories/components/Notification"

const CalendarEventForm = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isSubmitting,
   } = props;
   
   const {
      eventDate,
      eventKey,
      handleResolve,
      handleReject,
      notify,
   } = props;
  
  // Message templates
  const [messageTemplates, setMessageTemplates] = useState([])
  
  const getMessageTemplates = () => {
    httpGetCalendarMessageTemplates().then(res => {
      setMessageTemplates(res)
    })
  }
  
  useEffect(() => {
    getMessageTemplates()
  }, [])
  
  const handleSetMessageTemplate = (id) => {
    
    let messageTemplate = messageTemplates.reduce((result, item) => {
      if (item.id === id) { result = item }
      return result
    }, null)
    let message = messageTemplate?.name;
    setFieldValue("description", message)
  }
  
  const getMessageTemplateItems = () => {
    return messageTemplates && messageTemplates.map(mt => {
      return {
        label: mt.name.slice(0,16),
        props: {
          onClick: () => handleSetMessageTemplate(mt.id),
        }
      }
    })
  }
   
  // Form
  const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
   const handleClose = () => {
    handleReject();
  }
  
  const handleDelete = () => {
    let result = window.confirm("Poistetaanko?");
    if (result) {
      httpDeleteCalendarEvent(props.eventKey).then(res => {
        notify({ title:"Tapahtuma", message:"Poistettu onnistuneesti"})
        handleResolve();
      },error => {
        notify({ title:"Tapahtuma", message:"Poisto epäonnistui"})
      })
    }
  }
  
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="mb-3">
            <Typography bold variant="h2" style={{textTransform:"capitalize"}}>{eventDate.format("dddd")}</Typography>
          </Col>
          <Col className="mb-3 text-center">
            <Typography bold variant="h2">{eventDate.format("D.M.YYYY")}</Typography>
          </Col>
          <Col className="mb-3">
            
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("start_time")}
              label="Aloitusaika"
              type="time"
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("end_time")}
              label="Lopetusaika"
              type="time"
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("name")}
              label="Tapahtuman nimi"
              value={values.name}
              type="text"
            />
            
          </Col>
        </Row>
        <Row>
          <Col className="mb-2">
            <Input
              {...getFormikInputProps("description")}
              label="Vakioviestipohja"
              value={values.description}
              type="textarea"
            />
            { values.description && values.description.length > 0 ? (
              <div className="mt-2">
                Ohje
                <div className="small">
                  <ul>
                    <li>
                      <span className="text-primary">{"{date}"}</span> Järjestelmä lisää automaattisesti tapahtuman päivän tähän kohtaan viestissä.
                    </li>
                    <li>
                     <span className="text-primary">{"{weekday}"}</span> Järjestelmä lisää automaattisesti tapahtuman viikonpäivän tähän kohtaan viestissä.
                    </li>
                    <li>
                     <span className="text-primary">{"{starttime}"}</span> Järjestelmä lisää automaattisesti tapahtuman aloitusajan tähän kohtaan viestissä.
                    </li>
                    <li>
                     <span className="text-primary">{"{eventname}"}</span> Järjestelmä lisää automaattisesti tapahtuman nimen tähän kohtaan viestissä.
                    </li>
                    <li>
                     <span className="text-primary">{"{firstname}"}</span> Järjestelmä lisää automaattisesti tapahtuman luoneen käyttäjän etunimen tähän kohtaan viestissä.
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}
            <div style={{float:'right'}} className="mt-1">
              <Dropdown color="primary" size="normal" label="Valitse vakioviestipohja" items={getMessageTemplateItems()} />
            </div>
          </Col>
        </Row>
        
        { !eventKey && (
        <Row>
          <Col className="mb-3">
            <div style={{float:'right'}} className="mt-2">
              <div style={{float:'right'}} className="mr-3">
                <Toggle
                  {...getFormikInputProps("send_chat_message_notification")}
                  defaultChecked={values?.send_chat_message_notification}
                  labelOn="Kyllä"
                  labelOff="Ei"
                />
              </div>
              <div style={{float:'right'}} className="mr-1">
                Lähetä ilmoitus keskusteluun 
              </div>
              
            </div>
          </Col>
        </Row>
        )}
        
        <Row>
          <Col className="mb-3">
            <Button variant="default" onClick={handleClose}>Peruuta</Button>
          </Col>
          { eventKey ? (
            <>
              <Col className="mb-3 text-center">
                <Button variant="danger" onClick={handleDelete}>Poista</Button>
              </Col>
              <Col className="mb-3 text-right">
                <Button disabled={!isValid || isSubmitting} variant="primary" onClick={handleSubmit}>Tallenna</Button>
              </Col>
            </>
          ) : (
            <Col className="mb-3 text-right">
              <Button disabled={!isValid || isSubmitting} variant="primary" onClick={handleSubmit}>Lisää tapahtuma</Button>
            </Col>
          )}
          
        </Row>
        
      </Container>
    </>
  );
}

const replaceTags = (content, values, eventDate, myUser) => {
  // Tags
  content = content.replace("{date}", eventDate.format("DD.MM.YYYY"))
  content = content.replace("{weekday}", eventDate.format("dddd"))
  content = content.replace("{starttime}", values?.start_time)
  content = content.replace("{eventname}", values?.name)
  content = content.replace("{firstname}", myUser?.first_name)
  return content
}

 const CalendarEventFormik = withFormik({
    
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          id: 1,
        };
      }
    },
    validationSchema: props => {
      const required = "Kenttä on pakollinen";
      return Yup.object().shape({
        start_time: Yup.string().required(required),
        end_time: Yup.string().required(required),
        name: Yup.string().max(120, "Merkkiraja (120) ylitetty").required(required),
        description: Yup.string().max(400, "Merkkiraja ylitetty")
          .when("send_chat_message_notification", {
            is: true,
            then: Yup.string().required("Valitse vakioviestipohja")
          })
      });
    },
    handleSubmit: (values, { setSubmitting, props }) => {
      let date = props.eventDate.format("DD/MM/YYYY");
      
      let descriptionTemplate = replaceTags(values?.description, values, props.eventDate, props.myUser)
      
      let data = {
        ...values,
        timestart: moment(date + ' ' + values?.start_time, 'DD/MM/YYYY HH:mm'),
        timeend: moment(date + ' ' + values?.end_time, 'DD/MM/YYYY HH:mm'),
        description: descriptionTemplate && descriptionTemplate.length > 0 ? descriptionTemplate : "Tapahtumalla ei ole kuvausta",
      }
      
      if (props?.eventKey) { // Edit
        httpUpdateCalendarEvent(props.eventKey, data).then(res => {
          setSubmitting(false);
          props.notify({ title:"Tapahtuma", message:"Tallennettu onnistuneesti"})
          props.handleResolve();
        },error => {
          setSubmitting(false);
          props.notify({ title:"Tapahtuma", message:"Tallennus epäonnistui"})
        })
      }
      else { // Create
        data.owneruserid = props.user.id
        
        httpCreateCalendarEvent(data).then(res => {
          setSubmitting(false);
          props.notify({ title:"Tapahtuma", message:"Luotu onnistuneesti"})
          props.handleResolve();
        },error => {
          setSubmitting(false);
          props.notify({ title:"Tapahtuma", message:"Tallennus epäonnistui"})
        })
      }
      
    },
    displayName: "BasicForm"
  
  })(CalendarEventForm)
    

const defaultValues = {
  start_time: "0:00",
  end_time: "1:00",
  name: "",
  description: "",
  send_chat_message_notification: true,
}

const CalendarEvent = ({eventKey, eventDate, ...rest}) => {
  // API requests here
  const [eventData, setEventData] = useState();
  const { myUser } = useAuthContext()
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  useEffect(() => {
    if (eventKey) {
      httpGetCalendarEvent(eventKey).then(res => {
        setEventData({
          ...res,
          start_time: moment(res?.timestart).format("HH:mm"),
          end_time: moment(res?.timeend).format("HH:mm"),
          eventDate: moment(res?.timestart),
          // description: "",
        });
      })
    }
    else {
      setEventData({
        ...defaultValues,
        eventDate: moment(eventDate),
      });
    }
  }, [eventKey])
  
  if (eventData) {
    return (
      <CalendarEventFormik
        preSetValues={eventData}
        eventDate={eventData?.eventDate}
        eventKey={eventKey}
        myUser={myUser}
        notify={notify}
        {...rest}
      />
    )
  }
  return null;
  
}
  

export default CalendarEvent;
