import React, { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useAuthContext }from "contexts/AuthContext"

import { Typography, Button } from "stories/components";
import { Container, Row, Col } from "stories/layout";

const MyUser = ({ history }) => {
  
  const { user } = useAuth0();
  const { myUser, getMyUser } = useAuthContext()
  
  useEffect(() => {
    getMyUser();
  }, [])
  
  const handleEdit = () => {
    history.push(`/dashboard/users/edit/${myUser.id}/`);
  }
  
  return (
    <>
      <Container fluid>
        <Row className="mt-3 mb-5">
          <Col>
            <Typography bold variant="h2" className="mb-0">Omat tiedot</Typography>
          </Col>
        </Row>
        
        <Row className="mb-3">
          <Col>
            { myUser?.is_staff ? (
              <img src={user.picture} />
            ) : (
              <img style={{maxWidth: "128px"}} src={require("assets/images/aspaIcon.png").default} />
            )}
            
          </Col>
        </Row>
        
        <Row>
          <Col>
            <Typography bold>Nimi</Typography>
          </Col>
          <Col>
            <Typography bold>{myUser?.first_name} {myUser?.last_name}</Typography>
          </Col>
        </Row>
        
        <Row>
          <Col>
            <Typography bold>Puhelin</Typography>
          </Col>
          <Col>
            <Typography bold>{myUser?.phone}</Typography>
          </Col>
        </Row>
        
        <Row>
          <Col>
            <Typography bold>Sähköposti</Typography>
          </Col>
          <Col>
            <Typography bold>{myUser?.email}</Typography>
          </Col>
        </Row>
        
        {/*
        <Row>
          <Col>
            <Typography bold>Tunnus</Typography>
          </Col>
          <Col>
            <Typography bold>{user.username}</Typography>
          </Col>
        </Row>
        */}
        
        <Row className="mb-5">
          <Col>
            <Typography bold>Sähköposti vahvistettu (Auth0)</Typography>
          </Col>
          <Col>
            { user?.email_verified ? "Kyllä" : "Ei" }
          </Col>
        </Row>
        
        <Row className="mb-5">
          <Col>
            <Button onClick={handleEdit}>Muokkaa</Button>
          </Col>
        </Row>
        
        
        
      </Container>
    </>
  );
}

export default MyUser;
