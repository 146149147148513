import http, { authHeader } from './index'

const url = '/organizations/'

export const httpGetOrganizations = (params) => {
  console.log("httpGetOrganizations");
  return http({
    url: url,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    params,
  })
}

export const httpGetOrganization = (id) => {
  console.log("httpGetOrganization");
  return http({
    url: `${url}${id}/`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
  })
}

export const httpCreateOrganization = (data) => {
  console.log("httpCreateOrganization");
  return http({
    url: url,
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    data,
  })
}

export const httpCreateOrganizationAndInvitation = (data) => {
  console.log("httpCreateOrganizationAndInvitation");
  return http({
    url: "/create_organization_and_send_invitation/",
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    data,
  })
}

export const httpUpdateOrganization = (id, data) => {
  console.log("httpUpdateOrganization");
  return http({
    url: `${url}${id}/`,
    method: 'PATCH',
    headers: {
      ...authHeader(),
    },
    data,
  })
}

// Organization user roles

export const httpGetOrganizationUserRoles = (params) => {
  console.log("httpGetOrganizations");
  return http({
    url: `/organization_user_roles/`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    params,
  })
}

export const httpGetOrganizationUserRole = (id) => {
  console.log("httpGetOrganizationUserRole");
  return http({
    url: `/organization_user_roles/${id}/`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
  })
}

export const httpCreateOrganizationUserRole = (data) => {
  console.log("httpCreateOrganizationUserRole");
  return http({
    url: `/organization_user_roles/`,
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    data,
  })
}

export const httpUpdateOrganizationUserRole = (id, data) => {
  console.log("httpUpdateOrganizationUserRole");
  return http({
    url: `/organization_user_roles/${id}/`,
    method: 'PATCH',
    headers: {
      ...authHeader(),
    },
    data,
  })
}

export const httpDeleteOrganizationUserRole = (id) => {
  console.log("httpDeleteOrganizationUserRole");
  return http({
    url: `/organization_user_roles/${id}/`,
    method: 'DELETE',
    headers: {
      ...authHeader(),
    },
  })
}

// Organization units

export const httpGetOrganizationUnits = (params) => {
  console.log("httpGetOrganizationUnits");
  return http({
    url: `/organization_units/`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    params,
  })
}

export const httpGetOrganizationUnit = (id) => {
  console.log("httpGetOrganizationUnit");
  return http({
    url: `/organization_units/${id}/`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
  })
}

export const httpCreateOrganizationUnit = (data) => {
  console.log("httpCreateOrganizationUnit");
  return http({
    url: `/organization_units/`,
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    data,
  })
}

export const httpUpdateOrganizationUnit = (id, data) => {
  console.log("httpUpdateOrganizationUnit");
  return http({
    url: `/organization_units/${id}/`,
    method: 'PATCH',
    headers: {
      ...authHeader(),
    },
    data,
  })
}


export const httpDeleteOrganizationUnit = (id) => {
  console.log("httpDeleteOrganizationUnit");
  return http({
    url: `/organization_units/${id}/`,
    method: 'DELETE',
    headers: {
      ...authHeader(),
    },
  })
}

// Organization customer

// Organization units

export const httpGetOrganizationCustomers = (params) => {
  console.log("httpGetOrganizationCustomers");
  return http({
    url: `/organization_customers/`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    params,
  })
}

export const httpGetOrganizationCustomer = (id) => {
  console.log("httpGetOrganizationCustomer");
  return http({
    url: `/organization_customers/${id}/`,
    method: 'GET',
    headers: {
      ...authHeader(),
    },
  })
}

export const httpCreateOrganizationCustomer = (data) => {
  console.log("httpCreateOrganizationCustomer");
  return http({
    url: `/organization_customers/`,
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    data,
  })
}

export const httpUpdateOrganizationCustomer = (id, data) => {
  console.log("httpUpdateOrganizationCustomer");
  return http({
    url: `/organization_customers/${id}/`,
    method: 'PATCH',
    headers: {
      ...authHeader(),
    },
    data,
  })
}


export const httpDeleteOrganizationCustomer = (id) => {
  console.log("httpDeleteOrganizationCustomer");
  return http({
    url: `/organization_customers/${id}/`,
    method: 'DELETE',
    headers: {
      ...authHeader(),
    },
  })
}

// Organization user invitation

export const httpCreateOrganizationUserInvitation = (data) => {
  console.log("httpSendOrganizationUserInvitation");
  return http({
    url: `/organization_user_role_invitations/`,
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    data
  })
}

export const httpSendOrganizationUserInvitation = (id) => {
  console.log("httpSendOrganizationUserInvitation");
  return http({
    url: `/organization_user_role_invitations/${id}/send_invitation/`,
    method: 'POST',
    headers: {
      ...authHeader(),
    },
  })
}

